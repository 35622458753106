import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeWindow, openWindow } from "../model/servicesSlice";
import { useState } from "react";
import { OrderCalc } from "entities/orders/model/calc";
import { 
  useGetServiceTypesQuery,
  useCreateServiceMutation 
} from "../model/rtk-api";
import { useGetOrderByIdQuery } from "entities/orders/model/rtk-api";
import { useSearchParams } from "react-router-dom";

interface Props {
  quoteId: string;
  orderId: string;
}

export const ServiceCreateWindow = ({ quoteId, orderId }: Props) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.services.windows.serviceCreateWindow
  );
  const [searchParams, setSearchParams] = useSearchParams();
  

  const { data: serviceTypes = [] } = useGetServiceTypesQuery();
  const [createService] = useCreateServiceMutation();
  const {data:order} = useGetOrderByIdQuery(orderId);

  const orderCalc = order ? new OrderCalc(order) : null;

  const [serviceTypeId, setServiceTypeId] = useState(
    serviceTypes[0]?.id || ''
  );

  const serviceType = serviceTypes.find(
    type => type.id === serviceTypeId
  );

  const createHandler = async () => {
    if (!serviceType || !orderCalc) return;

    dispatch(closeWindow("serviceCreateWindow"));
    
    try {
      const result = await createService({
        quote: quoteId,
        order: orderId,
        service_type: serviceTypeId,
        margin: serviceType.default_margin || 0,
        margin_in_cost: serviceType.margin_in_cost,
        cost:
          serviceType.label === "veicolo"
            ? orderCalc.calcCost()
            : serviceType.default_cost || 0,
      }).unwrap();
      
      searchParams.set("service", result.id);
      setSearchParams(searchParams);
      dispatch(openWindow("serviceFormWindow"));
    } catch (error) {
      console.error('Failed to create service:', error);
    }
  };

  const typeHandleChange = (event: SelectChangeEvent) => {
    setServiceTypeId(event.target.value);
  };

  const closeHandler = () => {
    dispatch(closeWindow("serviceCreateWindow"));
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <FormControl fullWidth size="small">
          <InputLabel id="type">Tipo</InputLabel>
          <Select
            style={{ width: "300px" }}
            labelId="type"
            value={serviceTypeId}
            label="Tipo"
            onChange={typeHandleChange}
          >
            {serviceTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.label.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler}>Chiudi</Button>
        <Button onClick={createHandler}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
