import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Popover,
  Stack,
  TextField,
} from "@mui/material";

import { useEffect, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import { SendEmailForm } from "shared/ui/SendEmail/SendEmailForm";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { useGetContactByIdQuery } from "entities/crm/contacts/model/rtkapi";
import { useGetLeadByIdQuery, useUpdateLeadMutation } from "../model/qapi";
import { useGetPublicByOrderIdQuery, } from "entities/publics/model/api";
import { useCreateCommentMutation, useGetCommentsQuery } from "entities/comments/model/rtkapi";

interface Props {
  leadId: string;
}

export const NewLeadAction = ({ leadId }: Props) => {
  const [updateLead] = useUpdateLeadMutation();
  const { data: lead } = useGetLeadByIdQuery(leadId);
  const { data: contact } = useGetContactByIdQuery(lead?.contact);
  const { data: publics } = useGetPublicByOrderIdQuery(lead?.ref || "", { skip: !lead?.ref });
  const [price, setPrice] = useState(lead?.price);
  const [isSending, setIsSending] = useState(false);
  const [sendEmailForm, setSendEmailForm] = useState(false);
  const [phoneResultText, setPhoneResultText] = useState("");
  const [createComment] = useCreateCommentMutation();
  const { data: comments } = useGetCommentsQuery({ leadId: leadId });
  const called = comments?.some(comment => comment.text.includes("Telefonata"));

  useEffect(() => {
    if (publics) {
      setPrice(publics.price);
    }
  }, [publics]);

  let messageText = "";

  if (publics) {
    messageText = `Buongiorno. La contatto da Alpiservice SRL perchè ha richiesto il prezzo per l'annuncio https://www.alpiservice.net/veicoli/${publics?.slug}. \nIl prezzo orientativo sarà di ${price} euro IVA esclusa. Il mezzo attualmente disponibile può cambiare il suo stato per eventuali altre richieste. \nResto a sua completa disposizione.\n\n`;
  }

  const messageTemplate = `Buongiorno. La contatto da Alpiservice SRL perchè ha richiesto il prezzo per l'annuncio XXXXXX. \nIl prezzo orientativo sarà di ${price} euro IVA esclusa. Il mezzo attualmente disponibile può cambiare il suo stato per eventuali altre richieste. \nResto a sua completa disposizione.\n\n`;

  const initUpdateHandler = (type: string) => {
    //setIsSending(true);
    if (type === "Email") setSendEmailForm(true);
  };

  // const priceHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const pr = event.target.value;
  //   setPrice(pr);
  //   updateLead({ id: leadId, price: pr === "" ? 0 : Number(pr) });
  // };

  const handlePhoneResult = () => {
    createComment({
      lead: leadId,
      order: "",
      text: "Telefonata: " + phoneResultText,
    });
  };

  return (
    <>
      {contact && contact.phone && !called &&
        <>
          <div>

            {`Questo lead ha un contatto con il numero di telefono ${contact.phone}.
              Prima prova a contattarlo con cellulare o WhatsApp  `}
            {contact.phone && contact.wa && (
              <Link
                href={`https://wa.me/${contact.phone}?text=${messageText}`}
                target="blank"
                onClick={() => initUpdateHandler("WhatsApp")}
              >
                <WhatsAppIcon className="text-green-700 mx-2" />
              </Link>
            )}

            {contact.phone && (
              <Link
                href={`tel:${contact.phone}`}
                onClick={() => initUpdateHandler("Chiamata")}
              >
                <CallIcon className="text-blue-500 mx-2" />
              </Link>
            )}

            <div className="flex items-center mt-4 gap-2">
              <input
                className="w-full p-2 border border-gray-300 rounded-md"
                value={phoneResultText}
                onChange={(e) => setPhoneResultText(e.target.value)}
                placeholder="Scrivi il risultato della chiamata"
              />
              <Button variant="outlined" onClick={handlePhoneResult}>Invia</Button>
            </div>
          </div>

        </>
      }
      {/* {publics && publics.price && (
        <div>Prezzo dell'annuncio: {publics.price}</div>
      )} */}

      {contact && (called || !contact.phone) && (
        <>
          {/* <TextField
              size="small"
              sx={{ width: "120px" }}
              value={price}
              label="Prezzo"
              type="number"
              onChange={priceHandler}
              inputProps={{ min: "0", max: "300000", step: "1000" }}
            /> */}


          {contact.email && (
            <Button variant="outlined" onClick={() => initUpdateHandler("Email")}>
              Invia un messaggio su email  <EmailIcon sx={{ cursor: "pointer" }} />
            </Button>
          )}

          <PopupState variant="popover">
            {(popupState) => (
              <div>
                {/* <TextSnippetIcon
                      sx={{ cursor: "pointer" }}
                      {...bindTrigger(popupState)}
                    /> */}
                <Popover {...bindPopover(popupState)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "300px",
                      padding: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() =>
                        navigator.clipboard.writeText(messageTemplate)
                      }
                    >
                      {messageTemplate}
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </PopupState>

        </>
      )}

      {sendEmailForm && (
        <SendEmailForm
          isOpen={sendEmailForm}
          setIsOpen={setSendEmailForm}
          contact={contact}
          subject={`Alpiservice richiesta prezzo`}
          messageText={messageText}
          action={{ type: "lead", id: leadId }}
        />
      )}

      <Dialog open={isSending}>
        <DialogContent>Sei riuscito a comunicare il prezzo?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSending(false)}>No</Button>
          <Button>Si</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
