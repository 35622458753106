import { Box, Stack, Typography } from "@mui/material";
import { Vehicle } from "../api/types";
import { useAppDispatch } from "app/hooks";
import { openVehicleWindow } from "../api/vehiclesSlice";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { GiEuropeanFlag } from "react-icons/gi";
import { MdError } from "react-icons/md";
import { OriginIcon } from "entities/crm/leads/ui/OriginIcon";
import { Plate } from "shared/ui/Plate";

interface Props {
  vehicle: Vehicle;
}

export const VehicleCard = ({ vehicle }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const openVehicleHandler = () => {
    dispatch(openVehicleWindow("vehicleWindow"));
    searchParams.set("vehicle", vehicle.id);
    setSearchParams(searchParams);
  };
  return (
    <>
      <Box
        sx={{
          border: "1px dotted grey",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => openVehicleHandler()}
      >
        <Stack
          sx={{
            padding: "5px",
            // backgroundColor:
            //   vehicle.model === "UST"
            //     ? "#fff4d6"
            //     : vehicle.model === "SEM"
            //     ? "#e5f1ff"
            //     : "lightgrey",

            backgroundColor:
              vehicle.status === "to_order"
                ? "#f85a40"
                : vehicle.status === "to_invoice"
                ? "#ffd900"
                : vehicle.status === "to_register"
                ? "#84bd00"
                : vehicle.status === "to_delivery"
                ? "#84bd00"
                : "lightgrey",
          }}
        >
          <Box id="card_header">{vehicle.company_name}</Box>
          <Typography fontSize={10}>{vehicle.seller_name}</Typography>
        </Stack>

        <Stack sx={{ padding: "5px" }}>
          <Stack direction="row" justifyContent={"space-between"}>
            <Typography fontSize={12}>
              {vehicle.abbreviation
                ? vehicle.abbreviation.substring(0, 10)
                : vehicle.model.substring(0, 10)}{" "}
              {vehicle.model_desc && vehicle.model_desc}
            </Typography>
            <Typography fontSize={12}>
              {vehicle.code ? vehicle.code : "----/-----"}
            </Typography>
          </Stack>

          {vehicle.status === "to_invoice" && (
            <div style={{ fontSize: "0.8rem" }}>
              <div>{vehicle.prod_status.toUpperCase()}</div>
              <div>VENDUTO: {dayjs(vehicle.dt_sale).format("DD/MM/YYYY")}</div>
            </div>
          )}

          {vehicle.status === "to_register" && (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ fontSize: "0.8rem" }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <span>{vehicle.status_saled.toUpperCase()} </span>
                {vehicle.status_saled === "fatturato incompleto" && (
                  <span style={{ color: "darkred" }}>
                    <MdError />
                  </span>
                )}{" "}
              </Stack>
              {vehicle.invoiced && (
                <div>{dayjs(vehicle.invoiced).format("DD/MM/YYYY")}</div>
              )}
            </Stack>
          )}

          {(vehicle.status === "to_delivery" ||
            vehicle.status === "delivered") && (
            <div className="flex w-full justify-end">
              <Plate plate={vehicle.plate} />
            </div>  
          )}

          {vehicle.status === "delivered" && (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ fontSize: "0.8rem" }}
              marginTop="2px"
            >
              <div>CONSEGNATO: </div>
              <div>{dayjs(vehicle.delivered).format("DD/MM/YYYY")}</div>
            </Stack>
          )}
        </Stack>
        <Box
          id="ref"
          fontSize={12}
          sx={{ position: "absolute", top: "3px", right: "3px" }}
        >
          {vehicle.id}
        </Box>
        <Box
          id="ref"
          fontSize={12}
          sx={{ position: "absolute", top: "13px", right: "3px" }}
        >
          <OriginIcon origin={vehicle.lead_origin} />
        </Box>
      </Box>
    </>
  );
};
