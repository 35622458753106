import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { NewUsed, Used } from "./types";


export const usedApi = createApi({
  reducerPath: "usedApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/used",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Useds", "Used"],
  endpoints: (builder) => ({
    getUseds: builder.query({
      query: (search_string) => `?${search_string}`,
      providesTags: ["Useds"],
    }),
    getUsedById: builder.query({
      query: (id) => `${id}`,
      providesTags: ["Used"],
    }),
    addUsed: builder.mutation({
      query: (used:NewUsed) => ({
        url: "/",
        method: "POST",
        body: used,
      }),
      invalidatesTags: ["Useds"],
    }),
    updateUsed: builder.mutation({
      query: (used: Used) => ({
        url: `${used.id}/`,
        method: "PUT",
        body: used,
      }),
      invalidatesTags: ["Useds", "Used"],
    }),
    removeUsed: builder.mutation({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetUsedsQuery,
  useGetUsedByIdQuery,
  useUpdateUsedMutation,
  useAddUsedMutation,
} = usedApi;
