// components/Renderers/OrderRenderer.tsx
import React from 'react';

const OrderRenderer: React.FC<{ data: any }> = ({ data }) => {
  console.log(data)
  return (
    <div className="p-4 border rounded bg-gray-50">
      <h2 className="text-lg font-bold">Order #{data.rif}</h2>
      <ul>
        {data.auftrag.map((item: any, index: number) => (
          <li key={index}>
            {item}
          </li>
        ))}
      </ul>
      <p>Lordo Listino: {data.lordoListino}</p>
      <p>Trasporto: {data.trasporto}</p>
      <p>Totale Veicolo Fattura: {data.veicoloFattura}</p>
      <p>Allestimento: {data.allestimento}</p>
      <p>Sconto efettivo: {(((data.lordoListino - (data.veicoloFattura - data.trasporto)) / data.lordoListino) * 100).toFixed(2)}%</p>
    </div>
  );
};
export default OrderRenderer;

