// components/Renderers/InvoiceRenderer.tsx
import React from 'react';

const InvoiceRenderer: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div className="p-4 border rounded bg-gray-50">
      <h2 className="text-lg font-bold">Invoice #{data.invoiceNumber}</h2>
      <p>Date: {data.date}</p>
      <p>Total Amount: {data.totalAmount}</p>
      <ul>
        {data.items.map((item: any, index: number) => (
          <li key={index}>
            {item.name}: {item.price} x {item.quantity}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InvoiceRenderer;
