import { createSlice } from "@reduxjs/toolkit";
import { Config, History, Order, OrderInlineEditStrings } from "types/order";
import { arrToMap } from "shared/utils";

export interface Entities {
  [id: string]: Order;
}

export interface OrderSlice {
  loading: boolean;
  type: string;
  entities: Entities;
  history: History | null;
  error: any;
  error_message: {};
  current: string;
  last: string;
  search: string;
  sorted: keyof Order;
  new_order: boolean;
  config: Config;
  filter_config: boolean;
  only_trucks: boolean;
  favorites: boolean;
  currentList: string;
  windows: Windows;
}

type Windows = {
  orderStockWindow: boolean;
  orderDetailWindowStand: boolean;
  orderWindow: boolean;
  newOrderWindow: boolean;
};

export const orderTypes = ["MAN", "SEMIRIMORCHIO", "USATO"];
export const orderStatus = ["in stock", "venduto", "incompleto", "fatturato"];
export const modelList = ["TGX", "TGL", "TGE", "TGM", "TGS", "SEM", "UST"];
const initConfig: Config = {
  id: "",
  type: "",
  top: "",
  cabine: "",
  susp: "",
  wheelbase: "",
  traction: "",
  trans: "",
  order: "",
  engine: "",
  carload: "",
  setup: "",
  color: "",
  options: "",
};

const initialState: OrderSlice = {
  loading: false,
  type: "MAN",
  entities: {},
  history: null,
  error: false,
  error_message: {},
  current: "",
  last: "",
  search: "",
  sorted: "updated",
  new_order: false,
  config: initConfig,
  filter_config: false,
  only_trucks: false,
  favorites: false,
  currentList: "in stock",
  windows: {
    orderStockWindow: false,
    orderDetailWindowStand: false,
    orderWindow: false,
    newOrderWindow: false,
  },
};

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = true;
      state.error_message = payload;
      state.loading = false;
    },
    resetError: (state) => {
      state.error = false;
      state.error_message = {};
    },
    setEntities: (state, { payload }) => {
      state.entities = arrToMap(payload);
      state.loading = false;
    },
    resetOrderList: (state) => {
      state.entities = {};
    },
    setHistory: (state, { payload }) => {
      state.history = payload;
      //state.loading = false; commented 8/05/23 error loading order
    },
    addEntity: (state, { payload }) => {
      state.entities[payload.id] = payload;
      state.loading = false;
    },
    setCurrent: (state, { payload }) => {
      state.current = payload;
      state.search = "";
    },
    setLast: (state, { payload }) => {
      state.last = payload;
    },
    setValue: (state, { payload }) => {
      state.entities[payload.id][
        payload.field as keyof OrderInlineEditStrings
      ] = payload.value;
    },
    setSorted: (state, { payload }) => {
      state.sorted = payload;
    },
    setManualCalc: (state, { payload }) => {
      state.entities[payload.id]["manual_calc"] = payload.value;
    },
    setNewOrder: (state, { payload }) => {
      state.new_order = payload;
    },
    setConfig: (state, { payload }) => {
      state.config[payload.field as keyof Config] = payload.value;
    },
    setOrderInitialConfig: (state, {payload}) => {
      state.entities[payload.orderId].config = initConfig;
    },
    setConfigOfOrder: (state, { payload }) => {
      state.entities[state.current].config[payload.field as keyof Config] =
        payload.value;
    },
    resetConfig: (state) => {
      state.config = initConfig;
    },
    setOrderConfig: (state) => {
      state.config = state.entities[state.current].config;
    },
    setConfigForCurrentOrder: (state, { payload }) => {
      state.entities[state.current].config = payload;
    },
    setFilterConfig: (state, { payload }) => {
      state.filter_config = payload;
    },
    setOrderType: (state, { payload }) => {
      state.type = payload;
    },
    setOnlyTrucks: (state, { payload }) => {
      state.only_trucks = payload;
    },
    setFavorites: (state, { payload }) => {
      state.favorites = payload;
    },
    setCurrentList: (state, { payload }) => {
      state.currentList = payload;
    },
    openWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = true;
    },
    closeWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = false;
    },
  },
});

export const {
  setLoading,
  setError,
  resetError,
  setEntities,
  resetOrderList,
  addEntity,
  setCurrent,
  setLast,
  setValue,
  setSorted,
  setManualCalc,
  setHistory,
  setNewOrder,
  setConfig,
  setOrderConfig,
  setOrderInitialConfig,
  setConfigForCurrentOrder,
  resetConfig,
  setFilterConfig,
  setOrderType,
  setOnlyTrucks,
  setFavorites,
  setCurrentList,
  openWindow,
  closeWindow,
  setConfigOfOrder,
} = orderSlice.actions;

export default orderSlice.reducer;
