// fileHandlers/fileReader.ts
import { detectContentType, DetectedContent } from 'FileAnalyzer/utils/contentTypeDetector';
import * as pdfjsLib from 'pdfjs-dist';

async function extractTextFromPdf(file: File): Promise<string> {
  const arrayBuffer = await file.arrayBuffer();
  const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
  let fullText = '';
  
  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const textContent = await page.getTextContent();
    const pageText = textContent.items.map((item: any) => item.str).join(' ');
    fullText += pageText + '\n';
  }
  
  return fullText;
}

interface FileAnalysisResult {
  contentType: string;
  data: Record<string, any>;
  format: 'json' | 'text' | 'pdf';
  originalText?: string;
}

export const readFileAndDetectContent = async (file: File): Promise<FileAnalysisResult> => {
  try {
    let text: string;
    const format = file.type === 'application/pdf' ? 'pdf' : 'text';
    
    // Estrazione del testo in base al tipo di file
    if (file.type === 'application/pdf') {
      text = await extractTextFromPdf(file);
    } else {
      text = await file.text();
    }

    // Prima proviamo a interpretare come JSON
    try {
      const jsonData = JSON.parse(text);
      const detected = detectContentType(jsonData);
      return {
        contentType: detected.type,
        data: detected.data,
        format: 'json'
      };
    } catch {
      // Se non è JSON, analizziamo il testo direttamente
      const detected = detectContentType(text);
      return {
        contentType: detected.type,
        data: detected.data,
        format,
        originalText: text
      };
    }
  } catch (error) {
    console.error('Errore durante la lettura del file:', error);
    throw new Error('Impossibile leggere il file');
  }
};
