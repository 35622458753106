import { useEffect, useState } from "react";
import { OrderFile } from "../model";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import { useUpdateFileMutation } from "../model/rtk-api";
import { FileItem } from "./FileItem";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface DocType {
  label: string;
  edit_fields: string;
  field_labels: string;
  roles: string;
  editable: boolean;
}

interface EditDocumentProps {
  document: OrderFile;
  types: DocType[];
}

interface NewDocument {
  id: string;
  type: string;
  dt_doc: string | null;
  summa: number;
}

export const EditDocument = ({ document, types }: EditDocumentProps) => {
  const [updateFile, { isLoading }] = useUpdateFileMutation();
  
  const [open, setOpen] = useState(false);
  const [doc, setDoc] = useState(document.type);
  const [summa, setSumma] = useState(
    document.summa.toString().replace(".", ",")
  );
  const [date, setDate] = useState<Dayjs | null>(
    document.dt_doc ? dayjs(document.dt_doc) : null
  );

  const typesList = types
    .filter((item) => item.editable === true)
    .map((item) => item.label)
    .sort();
  const currentType =
    types.filter((item) => item.label === doc)[0] || "SCONOSCIUTO";

  const fields = currentType.edit_fields?.split(",");
  const labels = currentType.field_labels?.split(",");

  const handleSave = async () => {
    try {
      const newDocument: NewDocument = {
        id: document.id,
        type: doc,
        dt_doc: date?.format("YYYY-MM-DD") || null,
        summa: parseFloat(summa.replace(",", ".")),
      };
      
      await updateFile(newDocument).unwrap();
      setOpen(false);
    } catch (error) {
      console.error('Failed to update document:', error);
    }
  };

  const handleSumma = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSumma(value);
  };

  useEffect(() => {
    setSumma(document.summa.toString().replace(".", ","));
  }, [document]);

  interface DocumentDateProps {
    label: string;
  }

  const DocumentDate = ({ label }: DocumentDateProps) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          value={date}
          maxDate={dayjs("2025-03-21")}
          inputFormat="DD/MM/YYYY"
          onChange={(newValue) => setDate(newValue)}
          renderInput={(params) => <TextField error size="small" {...params} />}
        />
      </LocalizationProvider>
    );
  };

  return (
    <>
      <div
        style={{
          color: document.type === "SCONOSCIUTO" ? "darkred" : "darkblue",
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        {document.type?.toUpperCase()}
      </div>
      <Dialog open={open}>
        <DialogContent>
          <Stack spacing={2}>
            <FileItem item={document} />

            {document.type && currentType.editable && (
              <Autocomplete
                size="small"
                disableClearable
                value={doc}
                options={typesList}
                sx={{ minWidth: "350px" }}
                onChange={(event: any, newValue: string) => {
                  setDoc(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Documento" />
                )}
              />
            )}

            {fields && fields.includes("dt_doc") && (
              <DocumentDate label={labels[fields.indexOf("dt_doc")]} />
            )}

            {fields && fields.includes("summa") && (
              <TextField
                value={summa}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={Number(summa) < 1}
                onChange={handleSumma}
                label={labels[fields.indexOf("summa")]}
                id="outlined-start-adornment"
                sx={{ m: 1 }}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button 
            variant="contained" 
            onClick={() => setOpen(false)}
            disabled={isLoading}
          >
            INDIETRO
          </Button>
          <Button 
            variant="contained" 
            onClick={handleSave}
            disabled={isLoading}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
