import { useAppDispatch, useAppSelector } from "app/hooks";

import { Button, Stack, Typography } from "@mui/material";
import { getViewer } from "entities/viewer/model";
import { QuoteCard } from "entities/quotes/ui/QuoteCard";
import { ProgressLoader } from "shared/ui/ProgressLoader";

import { useSearchParams } from "react-router-dom";
import { useCreateQuoteMutation, useGetQuotesQuery } from "entities/quotes/model/rtk-api";
import { useGetLeadByIdQuery } from "../../crm/leads/model/qapi";
import { openWindow } from "app/windows/windowsSlice";

interface Props {
  leadId: string;
}

export const LeadQuotes = ({ leadId }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // const [newQuote, setNewQuote] = useState(false);
  const userId = useAppSelector(getViewer)?.id;
  const { data: quotes, isLoading } = useGetQuotesQuery({ leadId: leadId });
  const {data: lead} = useGetLeadByIdQuery(leadId);
  const [createQuote] = useCreateQuoteMutation();
  const createQuoteHandler = async () => {
    try {
      const result = await createQuote({ lead: leadId, owner: userId }).unwrap();
      if (result?.id) {
        searchParams.set("quote", result.id);
        setSearchParams(searchParams);
        dispatch(openWindow("quoteFormWindow"));
      }
    } catch (error) {
      console.error('Failed to create quote:', error);
    }
  };

  return (
    <Stack className="block" spacing={1}>
      <div>PREVENTIVI</div>
      <Stack spacing={1}>
        {quotes && quotes.length > 0 && (
          <Stack spacing={1} width="100%">
            {quotes.map((item) => (
              <QuoteCard key={item.id} quote={item} />
            ))}
          </Stack>
        )}
        {lead && lead.company ? (
          <Button
            sx={{ maxWidth: "250px" }}
            variant="outlined"
            onClick={() => createQuoteHandler()}
          >
            Nuovo preventivo
          </Button>
        ) : (
          <Typography color="darkred" fontWeight="lighter">
            Per creare un preventivo devi aggiungere l'azienda
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
