import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Manager } from "./types";

export const managersApi = createApi({
  reducerPath: "managersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getManagers: builder.query<{ [key: number]: Manager }, void>({
      query: () => "managers/",
      transformResponse: (response: Manager[]) =>
        response.reduce(
          (acc, manager) => ({
            ...acc,
            [manager.user]: manager,
          }),
          {} as { [key: string]: Manager }
        ),
    }),
    getCurrentManager: builder.query<Manager, void>({
      query: () => "managers/get_current_manager/",
    }),
    getSellers: builder.query<Manager[], void>({
      query: () => "managers/?role=seller",
    }),
  }),
});

export const { useGetManagersQuery, useGetCurrentManagerQuery, useGetSellersQuery } = managersApi;
