export const TruckSVG = ({color}:{color?:string}) => {

  return (
    <svg
      width="40px"
      height="16px"
      viewBox="0 0 1227.7206 528.94086"
      version="1.1"
      id="svg603"
    >
      <defs id="defs600" />
      <g id="layer1" transform="translate(505.18361,122.66973)">
      <path
       d="m 629.61181,353.25924 h 65.5955 l 7.28839,-5.10117 c 0,0 17.12736,-0.73025 17.12736,-13.11981 l -3.6442,-52.84258 c 0,0 1.09361,-4.00755 3.6442,-6.9215 0,0 0.72672,-18.22097 -12.0262,-18.22097 v -14.94367 c 0,0 14.20989,-1.09008 14.94014,-10.56569 v -89.28453 c 0,0 -1.45697,-13.11627 -13.1198,-13.11627 V 120.0308 L 652.24956,-15.333562 c 0,0 -33.88783,-80.895471 -181.46889,-80.895471 v 7.104945 h -55.44608 c 0,0 0.61383,165.618579 0.61383,290.773548 l -0.61383,151.60978 h 214.27722"
       fill={color}
       id="path152" />
    <path
       d="m 654.75428,23.82477 36.07858,83.08622 v 45.91756 c 0,0 0,10.56922 -11.66283,10.56922 h -28.05994 c 0,0 -13.48317,-0.36336 -13.48317,-15.67039 v -32.43439 l -77.2548,-18.220971 c 0,0 -10.56923,-1.093611 -10.56923,-14.576778 V 37.307937 c 0,0 -0.1023,-13.483167 9.83898,-13.483167 h 95.11241"
       fill="#ffffff"
       id="path154" />
    <path
       d="m 547.73914,179.06816 v -10.20587 c 0,0 0.24342,-5.46452 6.80156,-5.46452 h 17.73414 l 4.86128,12.02619 c 0,0 1.45697,8.50195 -3.16089,8.50195 h -24.17234 l -2.06375,-4.85775"

       id="path156" />
    <path
       d="m 707.59686,257.05321 h -14.82019 c 0,0 -5.10117,0.97014 -5.10117,7.5318 v 9.47209 h 31.94756 c 0,0 2.794,-15.3035 -12.0262,-17.00389"

       id="path158" />
    <path
       d="M 404.40201,320.46149 V -96.229033 c 0,0 4.37091,-26.440697 -48.10478,-26.440697 h -861.48084 v 352.75661 h 64.13746 v 26.96633 h 42.7348 l 39.35518,63.40828 H 3.4383225 L 35.611655,257.05321 H 321.31579 l 34.98144,63.40828 h 48.10478"
       fill={color}
       id="path160" />
    <path
       d="M 284.14359,293.49515 H 97.562959 V 274.0571 H 284.14359 v 19.43805"
       fill={color}
       id="path162" />
    <path
       d="M 284.14359,316.81729 H 36.341905 V 302.96724 H 284.14359 v 13.85005"
       fill={color}
       id="path164" />
    <path
       d="M 85.900126,309.89226 H 58.933793 V 247.5776 h 26.966333 v 62.31466"
       fill={color}
       id="path166" />
    <path
       d="M 206.15854,278.73493 H 164.61543 V 247.5776 h 41.54311 v 31.15733"
       fill={color}
       id="path168" />
    <path
       d="M 268.10984,283.77613 H 225.83648 V 247.5776 h 42.27336 v 36.19853"
       fill={color}
       id="path170" />
    <path
       d="m 607.87364,338.9294 c 0,35.82811 -29.04419,64.87231 -64.8723,64.87231 -35.82811,0 -64.87231,-29.0442 -64.87231,-64.87231 0,-35.82458 29.0442,-64.8723 64.87231,-64.8723 35.82811,0 64.8723,29.04772 64.8723,64.8723"
       fill={color}
       id="path172" />
    <path
       d="m 607.87364,338.9294 c 0,35.82811 -29.04419,64.87231 -64.8723,64.87231 -35.82811,0 -64.87231,-29.0442 -64.87231,-64.87231 0,-35.82458 29.0442,-64.8723 64.87231,-64.8723 35.82811,0 64.8723,29.04772 64.8723,64.8723 z"
       fill="none"
       id="path174" />
    <path
       d="m -12.239122,338.9294 c 0,35.82811 -29.044193,64.87231 -64.872304,64.87231 -35.828114,0 -64.872304,-29.0442 -64.872304,-64.87231 0,-35.82458 29.04419,-64.8723 64.872304,-64.8723 35.828111,0 64.872304,29.04772 64.872304,64.8723"
      fill={color}
       id="path176" />
    <path
       d="m -12.239122,338.9294 c 0,35.82811 -29.044193,64.87231 -64.872304,64.87231 -35.828114,0 -64.872304,-29.0442 -64.872304,-64.87231 0,-35.82458 29.04419,-64.8723 64.872304,-64.8723 35.828111,0 64.872304,29.04772 64.872304,64.8723 z"
       fill={color}
       id="path178" />
    <path
       d="m -179.83678,338.9294 c 0,35.82811 -29.0442,64.87231 -64.87337,64.87231 -35.82811,0 -64.87265,-29.0442 -64.87265,-64.87231 0,-35.82458 29.04454,-64.8723 64.87265,-64.8723 35.82917,0 64.87337,29.04772 64.87337,64.8723"
       fill={color}
       id="path180" />
    <path
       d="m -179.83678,338.9294 c 0,35.82811 -29.0442,64.87231 -64.87337,64.87231 -35.82811,0 -64.87265,-29.0442 -64.87265,-64.87231 0,-35.82458 29.04454,-64.8723 64.87265,-64.8723 35.82917,0 64.87337,29.04772 64.87337,64.8723 z"
       fill="none"
       id="path182" />
      </g>
    </svg>
  );
};
