import dayjs from "dayjs";
import { Box, Stack } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { QuoteDelWindow } from "./QuoteDelWindow";
import ClearIcon from "@mui/icons-material/Clear";
import EmailIcon from "@mui/icons-material/Email";
import PreviewIcon from "@mui/icons-material/Preview";
import { SendEmailForm } from "shared/ui/SendEmail/SendEmailForm";
import { useState } from "react";
//import { updateLead } from "entities/crm/leads/model/api";
import {
  useGetLeadByIdQuery,
  useUpdateLeadMutation,
} from "entities/crm/leads/model/qapi";
import { useGetContactByIdQuery } from "entities/crm/contacts/model/rtkapi";
import { useSearchParams } from "react-router-dom";
import { Quote } from "../model/types";
import { getQuoteColor, useUpdateQuoteMutation } from "../model/rtk-api";
import { useGetCurrentManagerQuery } from "entities/managers/model/rtk-api";
import { openWindow } from "app/windows/windowsSlice";

interface Props {
  quote: Quote;
}

export const QuoteCard = ({ quote }: Props) => {
  const dispatch = useAppDispatch();
  const [sendEmail, setSendEmail] = useState(false);
  const { data: lead } = useGetLeadByIdQuery(quote.lead);
  const [searchParams, setSearchParams] = useSearchParams();
  // useAppSelector(
  //   (state) => arrToMap(state.leads.entities)[quote.lead]
  // );
  const { data: contact } = useGetContactByIdQuery(lead?.contact, {
    skip: !lead,
  });
  //   useAppSelector(
  //   (state) => state.contacts.entities[lead.contact]
  // );
  // const leadManager = useAppSelector((state) =>
  //   lead ? state.managers.entities[lead.user] : null
  // );
  const { data: leadManager } = useGetCurrentManagerQuery();
  const [updateLead] = useUpdateLeadMutation();
  const [updateQuote] = useUpdateQuoteMutation();

  const openQuoteHandler = () => {
    dispatch(openWindow("quoteFormWindow"));
    searchParams.set("quote", quote.id);
    setSearchParams(searchParams);
  };

  const sendEmailHandler = () => {
    setSendEmail(true);

    updateLead({
      id: quote.lead,
      status: "decision",
    });
    updateQuote({
      id: quote.id,
      sent: true,
    }).unwrap();
  };

  return (
    <Stack
      sx={{
        backgroundColor: getQuoteColor(quote.status),
        padding: "0.6rem",
      }}
      direction="row"
      spacing={1}
    >
      <Stack
        style={{ cursor: "pointer" }}
        direction="row"
        width="70%"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        onClick={() => openQuoteHandler()}
      >
        <div>PREVENTIVO #{quote.id} </div>
        <div>{quote.status}</div>
        <div>{dayjs(quote.created).format("DD/MM/YYYY")} </div>
        <Box sx={{ width: "70px", textAlign: { xs: "left", md: "right" } }}>
          €{quote.total_price && quote.total_price.toString()}
        </Box>
      </Stack>
      {/* {quote.expire && dayjs(quote.expire).format("DD/MM/YYYY")} */}
      <Stack
        width="30%"
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="right"
      >
        <a
          href={`${quote.file_path}?id=${Math.floor(Math.random() * 1000)}`}
          target="_blank"
          rel="noreferrer"
        >
          <PreviewIcon
            style={{ fontSize: "1.2rem", color: "grey", cursor: "pointer" }}
          />
        </a>

        {quote.status !== "accettato" && quote.status !== "venduto" && (
          <>
            {quote.status !== "non valido" && quote.status !== "scaduto" && (
              <div onClick={() => sendEmailHandler()}>
                <EmailIcon
                  style={{
                    fontSize: "1.2rem",
                    color: "grey",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
            <div onClick={() => dispatch(openWindow("quoteDelWindow"))}>
              <ClearIcon
                style={{
                  fontSize: "1.2rem",
                  color: "darkred",
                  cursor: "pointer",
                }}
              />
            </div>
          </>
        )}
      </Stack>
      {/* {quote.orders.length > 0 && (
          <Stack direction="row" spacing={1}>
            {quote.orders.map((order) => (
              <div key={order}>ref: {order} </div>
            ))}
          </Stack>
        )} */}

      <QuoteDelWindow quoteId={quote.id} />
      {sendEmail && leadManager && lead && (
        <SendEmailForm
          sender={leadManager}
          contact={contact}
          subject={`Preventivo #${quote.id} (${
            lead.company_name || lead.contact_str
          })`}
          messageText=""
          isOpen={sendEmail}
          setIsOpen={setSendEmail}
          action={{ type: "lead", id: quote.lead }}
          files={[quote.file_path]}
          filesToAttach={quote.files}
        />
      )}
    </Stack>
  );
};
