import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Service, ServiceType } from './types';

export const servicesApi = createApi({
  reducerPath: 'servicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/quote/',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Services', 'ServiceTypes', 'Service'],
  endpoints: (builder) => ({
    getServiceTypes: builder.query<ServiceType[], void>({
      query: () => 'service-type/',
      providesTags: ['ServiceTypes'],
    }),

    getServices: builder.query<Service[], { quoteId: string; orderId?: string }>({
      query: ({ quoteId, orderId = '' }) => `service/?quote=${quoteId}&order=${orderId}`,
      providesTags: ['Services'],
    }),

    createService: builder.mutation<Service, Partial<Service>>({
      query: (service) => ({
        url: 'service/',
        method: 'POST',
        body: service,
      }),
      invalidatesTags: ['Services'],
    }),

    updateService: builder.mutation<Service, Partial<Service>>({
      query: (service) => ({
        url: `service/${service.id}/`,
        method: 'PATCH',
        body: service,
      }),
      invalidatesTags: ['Services'],
    }),

    deleteService: builder.mutation<void, string>({
      query: (serviceId) => ({
        url: `service/${serviceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Services'],
    }),

    getServiceById: builder.query<Service, string>({
      query: (serviceId) => `service/${serviceId}/`,
      providesTags: ['Services'],
    }),

    getServiceTypeById: builder.query<ServiceType, string>({
      query: (serviceTypeId) => `service-type/${serviceTypeId}/`,
      providesTags: ['ServiceTypes'],
    }),
  }),
});

export const {
  useGetServiceTypesQuery,
  useGetServicesQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useGetServiceByIdQuery,
  useGetServiceTypeByIdQuery,
} = servicesApi; 