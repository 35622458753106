import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Box } from "@mui/material";
import { Routing } from "pages";

import MenuAppBar from "app/ui/appbar/appBar";
import { viewerState } from "entities/viewer/model";
import { useAppSelector } from "app/hooks";
import { ApiErrorMessage } from "entities/messages/ui/ApiErrorMessage";
import { Windows } from "app/windows/Windows";
import { RootState } from "app/store";


function App() {
  const viewer = useAppSelector(viewerState);
  const error = useAppSelector((state: RootState) => state.messages.error);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "1100px",
        bgcolor: "white",
        position: "relative",
        marginX: "auto",
      }}
    >
      {viewer.logged_in && <MenuAppBar />}

      <Routing />
      {error && <ApiErrorMessage message={error} />}
      <Windows />
    </Box>
  );
}

export default App;
