import { useGetHistoryQuery } from "entities/orders/model/rtk-api";
import { ManOrder } from "../model/types";
import { ManOrderCard } from "./ManOrderCard";

interface ManOrderListProps {
  results: ManOrder[];
  isLoading: boolean;
}

export const ManOrderList: React.FC<ManOrderListProps> = ({ results, isLoading }) => {
  const { data: history } = useGetHistoryQuery(null);
  if (isLoading) return <p>Loading...</p>;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {results.map((item) => (
        <div className={`${history && history.history.length > 0 && history?.history[0].order_id === item.id ? "border-2 border-orange-500 border-dotted rounded-md" : ""}`} key={item.id}>
          <ManOrderCard key={item.id} order={item} />
        </div>
      ))}
    </div>
  );
};
