import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getRoles } from "entities/viewer/model";
import { useEffect, useState } from "react";
import { Config, Order } from "types/order";
import { Configurator } from "./Configurator";
import {
  createConfig,
  setConfigOfOrder,
  setOrderInitialConfig,
  updateConfig,
} from "entities/orders/model";
import { ConfOptions } from "./ConfOptions";
import { useGetManConfigQuery } from "entities/man/model/api";
import { useUpdateManConfigMutation } from "entities/man/model/api";
import { initConfig } from "./constants";

interface ConfigCardProps {
  order: Order;
}

interface ConfigRowProps {
  title: string;
  value: string | null;
}

const ConfigRow = ({ title, value }: ConfigRowProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" marginY={1}>
      <div style={{ fontSize: "13px", fontWeight: "lighter" }}>{title}</div>
      <div style={{ fontSize: "13px" }}>{value}</div>
    </Stack>
  );
};

export const ConfigCard = ({ order }: ConfigCardProps) => {
  const [configurator, setConfigurator] = useState(false);
  const {data:config} = useGetManConfigQuery(order.config_id, {skip: !order.config_id}) || initConfig;
  const [updateManConfig] = useUpdateManConfigMutation();
  const dispatch = useAppDispatch();
  const roles = useAppSelector(getRoles);
  const [localConfig, setLocalConfig] = useState<Config>(initConfig);

  useEffect(() => {
    if (config) {
      setLocalConfig(config);
    }
  }, [config]);

  const openConfiguratorHandler = () => {
    setConfigurator(true);
  };

  const updateConfigHandler = async () => {
    if (localConfig.id !== "") {
      await updateManConfig(localConfig);
    }
    setConfigurator(false);
  };

  return (
    <>
      <Paper elevation={1} sx={{ padding: "10px" }}>
        <ConfigRow title="MODEL" value={order.model} />
        <ConfigRow title="NOME VARIANTE" value={order.abbreviation} />
        {config && (
          <>
            {config.type && <ConfigRow title="TYPE" value={config.type} />}
            {config.top && <ConfigRow title="TETTO" value={config.top} />}
            {config.cabine && (
              <ConfigRow title="CABINA" value={config.cabine} />
            )}
            {config.susp && (
              <ConfigRow title="SOSPENSIONI" value={config.susp} />
            )}
            {config.wheelbase && (
              <ConfigRow title="PASSO" value={config.wheelbase} />
            )}
            {config.traction && (
              <ConfigRow title="TRAZIONE" value={config.traction} />
            )}
            {config.trans && <ConfigRow title="CAMBIO" value={config.trans} />}
            {config.engine && (
              <ConfigRow title="MOTORE, hp" value={config.engine} />
            )}
            {config.carload && (
              <ConfigRow title="PORTATA" value={config.carload} />
            )}
            {config.setup && (
              <ConfigRow title="ALLESTIMENTO" value={config.setup} />
            )}
            {config.color && <ConfigRow title="COLORE" value={config.color} />}
          </>
        )}
        {config && <ConfOptions config={config} />}
        {order.status !== "fatturato" && roles?.includes("seller") && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              sx={{ alignContent: "right" }}
              variant="outlined"
              size="small"
              onClick={() => openConfiguratorHandler()}
            >
              MODIFICA
            </Button>
          </div>
        )}
      </Paper>

      <Dialog open={configurator} fullWidth>
        <DialogContent>
          <Configurator
            config={localConfig}
            setConfig={setLocalConfig}
            model={order.model}
            filter={false}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={() => updateConfigHandler()}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
