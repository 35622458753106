import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Quote } from './types';


export const quoteApi = createApi({
  reducerPath: 'quoteApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: '/api/quote/',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Quote', 'Leads'],
  endpoints: (builder) => ({
    createQuote: builder.mutation<Quote, Partial<Quote>>({
      query: (quote) => ({
        url: 'quote/',
        method: 'POST',
        body: quote,
      }),
      invalidatesTags: ['Quote', 'Leads'],
    }),

    updateQuote: builder.mutation<Quote, Partial<Quote>>({
      query: (quote) => ({
        url: `quote/${quote.id}/`,
        method: 'PATCH',
        body: quote,
      }),
      invalidatesTags: ['Quote', 'Leads'],
    }),

    createPdfQuote: builder.mutation<Quote, string>({
      query: (quoteId) => ({
        url: `create-pdf`,
        method: 'GET',
        params: { quote: quoteId },
      }),
      invalidatesTags: ['Quote'],
    }),
    
    getQuotes: builder.query<Quote[], { leadId?: string; orderId?: string }>({
      query: ({ leadId, orderId }) => ({
        url: 'quote/',
        params: { lead: leadId, order: orderId },
      }),
      providesTags: ['Quote'],
    }),

    getQuote: builder.query<Quote, string>({
      query: (quoteId) => `quote/${quoteId}`,
      providesTags: ['Quote'],
    }),

    addOrderToQuote: builder.mutation<Quote, { quoteId: string; orderId: string }>({
      query: ({ quoteId, orderId }) => ({
        url: 'add-order-to-quote/',
        method: 'POST',
        body: { quote: quoteId, order: orderId },
      }),
      invalidatesTags: ['Quote', 'Leads'],
    }),

    removeOrderFromQuote: builder.mutation<Quote, { quoteId: string; orderId: string }>({
      query: ({ quoteId, orderId }) => ({
        url: 'remove-order-from-quote/',
        method: 'POST',
        body: { quote: quoteId, order: orderId },
      }),
      invalidatesTags: ['Quote', 'Leads'],
    }),

    deleteQuote: builder.mutation<void, string>({
      query: (id) => ({
        url: `quote/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Quote', 'Leads'],
    }),

    createPdf: builder.mutation<Quote, string>({
      query: (quoteId) => ({
        url: `${quoteId}/pdf`,
        method: 'GET',
      }),
      invalidatesTags: ['Quote'],
    }),
  }),
});

export const {
  useCreateQuoteMutation,
  useUpdateQuoteMutation,
  useCreatePdfQuoteMutation,
  useGetQuotesQuery,
  useGetQuoteQuery,
  useAddOrderToQuoteMutation,
  useRemoveOrderFromQuoteMutation,
  useDeleteQuoteMutation,
  useCreatePdfMutation,
} = quoteApi;

// Вспомогательная функция для определения цвета статуса (перенесена из старого API)
export const getQuoteColor = (status: string) => {
  if (status === "non valido" || status === "scaduto") return "#f4cccc";
  if (status === "accettato") return "#dbf5e1";
  if (status === "venduto") return "#d9ead3";
  if (status === "bozza") return "#ffffff";
  return "#ddf5f1";
}; 