import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { Vehicle } from "../api/types";
import { useEffect, useState } from "react";
import { useGetVehicleByIdQuery, useGetVehiclesQuery, useUpdateVehicleMutation } from "../api/api";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

interface Props {
  vehicle_id: string;
}

export const VehicleActions = ({ vehicle_id }: Props) => {
  const [updVehicle, setUpdVehicle] = useState<Vehicle | null>(null);
  const {data: vehicle, refetch} = useGetVehicleByIdQuery(vehicle_id, {skip: !vehicle_id})
  const [updateVehicle, { error, isLoading: isUpdateingVehicle }] =
    useUpdateVehicleMutation();

  const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError =>
    "data" in error;

  const handleSave = async () => {
    await updateVehicle(updVehicle!);
    refetch();
  };

  useEffect(() => {
    setUpdVehicle(vehicle);
  }, [vehicle]);
  return (
    <div>
      {vehicle && vehicle.status !== "to_invoice" && (
        <>
          <Stack
            spacing={2}
            sx={{ border: "1px dotted grey", padding: "10px" }}
          >
            {!vehicle.code && (
              <>
                {vehicle.type === "MAN" && <Typography sx={{ width: "200px" }}>
                  PRIMA DEVI CARICARE L'ORDINE NUOVO DAL XLS E POI INSERIRE
                  L'AUFTRAG NEL FORMATO <span className="text-red-800">5005/XXXXX</span>
                </Typography>}
                {vehicle.type !== "MAN" && <div>INSERIRE CODICE ORDINE</div>}
                <TextField
                  size="small"
                  label="AUFTRAG"
                  value={updVehicle?.code}
                  onChange={(e) =>
                    setUpdVehicle({ ...updVehicle!, code: e.target.value })
                  }
                />
              </>
            )} 
    

            {vehicle.status === "to_register" && (
              <>
                <TextField
                  size="small"
                  label="TARGA"
                  value={updVehicle?.plate}
                  onChange={(e) => {
                    setUpdVehicle({ ...updVehicle!, plate: e.target.value });
                  }}
                />
              </>
            )}

            {vehicle.status === "to_delivery" && (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="CONSEGNATO IN DATA"
                    value={dayjs(updVehicle?.delivered)}
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => {
                      setUpdVehicle({
                        ...updVehicle!,
                        delivered: newValue
                          ? newValue.format("YYYY-MM-DD")
                          : "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}

            {error && isFetchBaseQueryError(error) && <>{error.data}</>}
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                handleSave();
              }}
            >
              SALVA
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};
