import { AppDispatch } from "app/store";
import axios from "axios";
import {
  // LeadRequest,
  Photo,
  //setCurrent,
  setEntities,
  setEntity,
  setLoading,
  setPhotos,
  setUploading,
  //setRequests,
} from "./publicsSlice";
import { setError } from "entities/messages/model/messagesSlice";
import { arrToMap } from "shared/utils";

export const getPublics =
  (search_string:string) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    await axios
      .get(`/api/public/adm?${search_string}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        if (data) dispatch(setEntities(arrToMap(data)));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("publics/getPublics"));
      });
  };

export const getPublic =
  (orderId: string) => async (dispatch: AppDispatch, getState: any) => {
    await axios
      .get(`/api/public/get-public/${orderId}/`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        if (data) dispatch(setEntity(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("publics/getPublic"));
      });
  };

export const createPublic =
  (orderId: string) => async (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    const user = state.viewer.user.id;
    const new_public = {
      order: orderId,
      created_by: user,
    };

    await axios
      .post(`/api/public/adm/`, new_public, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        dispatch(getPublic(orderId));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("publics/createPublic"));
      });
  };

export const createPermPublic =
  () => async (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    const user = state.viewer.user.id;
    const new_public = {
      created_by: user,
      title: "Annuncio permanente",
      subtitle: "sottotitolo",
      desc: "descrizione",
      permanent: true,
    };

    await axios
      .post(`/api/public/adm/`, new_public, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        dispatch(setEntity(response.data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("publics/createPermPublic"));
      });
  };

export const updatePublic =
  (obj: any) => async (dispatch: AppDispatch, getState: any) => {
    await axios
      .patch(`/api/public/adm/${obj.id}/`, obj, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        dispatch(setEntity(response.data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("publics/updatePublic"));
      });
  };

export const getPhotos =
  (publicId: string) => async (dispatch: AppDispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      // dispatch(setLoading(true));
      axios
        .get(`/api/public/photos/?public=${publicId}`, {
          headers: {
            Authorization: "token " + token,
          },
        })
        .then((response) => {
          let data = [];
          if (response.data) {
            data = response.data;
          }
          dispatch(setPhotos(data));
          //dispatch(setLoading(false));
        })
        .catch((er) => {
          console.log(er);
          dispatch(setError("publics/getPhotos"));
        });
    }
  };

export const uploadPhotos =
  (files: FileList, publicId: string) => async (dispatch: AppDispatch) => {
    dispatch(setUploading(true));
    const token = localStorage.getItem("token");
    const formData = new FormData();
    for (let i = 0; i < Object.values(files).length; i++) {
      formData.append("file", files[i]);
    }
    formData.append("public", publicId);
    await axios
      .post(`/api/public/photos/`, formData, {
        headers: {
          Authorization: "token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch(updatePhotosOrder());
        dispatch(getPhotos(publicId));
        dispatch(setUploading(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("publics/uploadPhotos"));
      });
  };

export const updatePhotosOrder =
  () => async (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    const photos = state.publics.photos;
    const updates = photos.map((item: Photo, index: Number) => {
      return { id: item.id, sequence: index };
    });
    await axios
      .post(`/api/public/update-photo-sequence/`, updates, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(getPhotos(state.publics.current.id));
      })
      .catch((error) => {
        console.log(error);
        //dispatch(setError("publics/updatePhotosOrder"));
      });
  };

export const deletePhoto = (id: string) => async (dispatch: AppDispatch) => {
  //dispatch(setLoading(true));
  const token = localStorage.getItem("token");
  await axios
    .delete(`/api/public/photos/${id}`, {
      headers: {
        Authorization: "token " + token,
      },
    })
    .then((response) => {})
    .catch((error) => {
      console.log(error);
      dispatch(setError("publics/deletePhoto"));
    });
};
