export const leadStatuses = {
  new: { seq: 0, title: "In arrivo", canAdd: true, showCol: true },
  discussion: { seq: 1, title: "Trattative", canAdd: true, showCol: true },
  decision: { seq: 2, title: "Decisione", canAdd: false, showCol: true },
  signing: { seq: 3, title: "Firma contratto", canAdd: false, showCol: true },
  signed: { seq: 4, title: "Contratto firmato", canAdd: false, showCol: false },
};

export const leadStatusesList = Object.keys(leadStatuses)
  .map((key) => {
    return {
      ...leadStatuses[key as keyof typeof leadStatuses],
      value: key,
    };
  })
  .sort((a, b) => a.seq - b.seq);

export const origins = {
  incoming_call: { seq: 0, title: "chiamata cellulare", auto: true },
  subito_message: { seq: 1, title: "messaggio Subito.it", auto: true },
  subito_missed: { seq: 2, title: "chiamata persa Subito.it", auto: true },
  price_request: { seq: 3, title: "richiesta prezzo sito", auto: true },
  manual_created: { seq: 4, title: "creato da utente", auto: true },
  truck1_message: { seq: 5, title: "Truck1.it", auto: true },
  linemedia_message: { seq: 6, title: "Autoline24.it", auto: true },
  old_client: { seq: 7, title: "cliente vecchio", auto: false },
  call_fixed: { seq: 8, title: "chiamata in entrata sul fisso", auto: false },
  call_cell: { seq: 9, title: "chiamata in entrata sul cellulare", auto: false },
  internet_search: { seq: 10, title: "ricerca internet", auto: false },
  offline_search: { seq: 11, title: "ricerca offline", auto: false },
  personal_request: { seq: 11, title: "richiesta personale", auto: false },
};

export const results = {
  no_contact: "non contatta",
  expensive: "prezzo elevato",
  long_time: "troppo tempo",
  sold: "già venduto",
  double: "duplicato",
  other: "altro",
};
