import { Button, Grid, Typography } from "@mui/material";
import { useApplyXlsReportChangesMutation, useGetXlsFileCheckQuery, useGetXlsQuery, useLoadXlsMutation } from "entities/files/model/rtk-api";
import { XlsFile } from "entities/files/model/types";
import { ChangeEvent, useState } from "react";
import Loader from "shared/ui/loader/loader";

export const ManageXlsPage = () => {
  const [xlsId, setXlsId] = useState<string>("");
  console.log(xlsId);
  const { data: files, isLoading: loading } = useGetXlsQuery(undefined);
  //const loading = useAppSelector(FilesStateSelector).loading;
  const { data: xlsCheck, isLoading: xlsCheckLoading } = useGetXlsFileCheckQuery(xlsId, {
    skip: xlsId === "",
    refetchOnMountOrArgChange: true
  });
  const [loadXls, { isLoading: loadXlsLoading }] = useLoadXlsMutation();
  const [applyXlsReportChanges, { isLoading: applyXlsReportChangesLoading }] = useApplyXlsReportChangesMutation();
  //const xlsReport = useAppSelector(FilesStateSelector).xls_report;

  const xlsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      loadXls(event.target.files[0]);
    }
  };

  const handleCheck = (id: string) => {
    setXlsId(id);
  }


  // useEffect(() => {
  //   dispatch(getXls());
  // }, []);

  if (loading || xlsCheckLoading || loadXlsLoading || applyXlsReportChangesLoading) return <Loader />;

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      marginTop={2}
      sx={{ paddingX: { xs: "10px", md: "0" } }}
    >
      <Grid item md={2}>
        <Button variant="contained" component="label">
          Carica XLS
          <input
            hidden
            accept=".xls, .xlsx"
            type="file"
            onChange={xlsHandler}
          />
        </Button>
      </Grid>
      <Grid item md={10} container direction="column" spacing={3}>
        {files.map((item: XlsFile) => (
          <Grid item container direction="row" spacing={2} key={item.id}>
            <Grid item xs={6} md={3}>
              <Typography fontSize={12}>
                {new Date(item.uploaded).toLocaleDateString("it-IT")}{" "}
                {item.name}{" "}
              </Typography>
            </Grid>
            {!item.changes && (
              <Grid item xs={6} md={9}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleCheck(item.id)}
                >
                  CHECK
                </Button>
              </Grid>
            )}
            {xlsCheck && xlsCheck?.length !== 0 &&
              xlsCheck[0] !== "Error" &&
              !item.changes && (
                <div className="flex flex-col items-center">
                  <div>
                    {xlsCheck.map((string: string, index: number) => (
                      <Typography key={index} fontSize={10} marginTop="2px">
                        {string}
                      </Typography>
                    ))}
                  </div>
                  <div className="mt-2">
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => applyXlsReportChanges(item.id)}
                    >
                      Applica
                    </Button>
                  </div>
                </div>
              )}
            {item.changes && (
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    //dispatch(setXlsReport(JSON.parse(item.changes)))
                    xlsId === item.id ? setXlsId("") : setXlsId(item.id)
                  }
                >
                  Rapporto
                </Button>
              </Grid>
            )}
            <Grid item>
              {item.changes && item.id === xlsId && JSON.parse(item.changes).map((string: string, index: number) => (
                <Typography key={index} fontSize={10} marginTop="2px">
                  {string}
                </Typography>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>

    </Grid>
  );
};
