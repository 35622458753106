import { useState } from "react";
import Loader from "shared/ui/loader/loader";
import { Box, Button, Stack, TextField } from "@mui/material";
import { CommentsList } from "./CommentsList";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { useSearchParams } from "react-router-dom";
import { Comment } from "../model/types";
import { useCreateCommentMutation } from "../model/rtkapi";
interface CommentsProps {
  list: Comment[];
}

export const Comments = ({ list }: CommentsProps) => {
  const [dialog, setDialog] = useState(false);
  const [comment, setComment] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const leadId = searchParams.get("lead");
  const orderId = searchParams.get("order");
  const [createComment, {isLoading}] = useCreateCommentMutation();

  const sendCommentHandler = () => {
    if (!comment) return;
    createComment({text: comment, order: orderId, lead: leadId});
    setDialog(false);
    setComment("");
  };
  if (isLoading) return <Loader />;
  return (
    <>
      <Stack direction="column" spacing={1} width="100%">
        <CommentsList list={list} />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            value={comment}
            sx={{ minWidth: { md: "80%", xs: "100%" } }}
            size="small"
            multiline
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComment(event.target.value);
            }}
          />
          <Button
            sx={{ width: { md: "10%", xs: "100%" } }}
            variant="outlined"
            onClick={() => sendCommentHandler()}
          >
            <AddCommentIcon />
          </Button>
        </Box>
        {/* <Button
          sx={{ maxWidth: "250px" }}
          variant="outlined"
          onClick={() => setDialog(true)}
        >
          Nuovo commento
        </Button> */}
      </Stack>

      {/* <Dialog open={dialog}>
        <DialogContent>
          <TextField
            sx={{ minWidth: "300px" }}
            multiline
            rows={5}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComment(event.target.value);
            }}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Annulla</Button>
          <Button onClick={() => sendCommentHandler()} variant="contained">
            INVIA
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};
