export const TrattoreSVG = ({ color }: { color?: string }) => {
  return (
    <svg
      style={{ transform: "scaleX(-1)" }}
      width="30px"
      height="20px"
      viewBox="0 0 89.733261 58.003719"
      version="1.1"
      id="svg816"
    >
      <defs id="defs813" />
      <g id="layer1" transform="translate(-21.753669,-68.137239)">
        <g
          id="g787"
          transform="matrix(0.35277777,0,0,-0.35277777,-155.96166,637.20306)"
        >
          <path
            d="m 513.932,1543.38 2.708,27.67 c 0.44,1.47 4.69,1.32 4.69,1.32 h 26.648 c 4.03,0 3.883,-3.3 3.883,-3.3 v -21.82 c 0,-3.07 -1.757,-3.15 -1.757,-3.15 l -34.636,-3.58 c -2.12,-0.22 -1.533,2.86 -1.536,2.86 z m -10.172,-26.59 v -15.17 c 0.222,-2.52 5.708,-5.27 5.708,-5.27 0.547,-1.42 -1.096,-3.95 -1.096,-3.95 v -3.07 c 0,-1.1 0.989,-1.21 0.989,-1.21 v -12.96 l -0.989,-1.53 v -8.68 c 0.439,-3.4 9.112,-3.56 9.112,-3.56 1.979,-1.6 18.112,-0.94 18.112,-0.94 v 1.87 h 3.887 c -0.816,2.42 -1.234,4.9 -1.234,7.39 0,12.79 10.406,23.2 23.199,23.2 12.794,0 23.203,-10.41 23.203,-23.2 0,-2.49 -0.42,-4.97 -1.236,-7.39 h 9.504 c 1.491,-1.24 3.412,-2.01 5.509,-2.01 h 38.67 c 3.312,0 6.188,1.89 7.611,4.65 h 2.491 v -4.65 h 49.866 v 4.65 h 5.837 c -0.332,1.59 -0.505,3.19 -0.505,4.75 0,12.79 10.407,23.2 23.197,23.2 12.79,0 23.197,-10.41 23.197,-23.2 0,-1.57 -0.174,-3.17 -0.505,-4.75 h 7.636 c 0,0 0.735,13.54 -1.903,18.95 l 4.102,1.47 v 4.83 l -22.112,6.3 h -50.322 c -0.793,2.65 -3.247,4.57 -6.151,4.57 h -32.742 c -2.901,0 -5.355,-1.92 -6.15,-4.57 h -30.436 v 82.74 c 0,30.46 -5.743,33.85 -5.743,33.85 -2.202,0 -8.674,-2.22 -8.674,-2.22 -24.675,0 -59.522,-16.55 -59.522,-16.55 v -1.99 l -15.374,-1.2 c -4.838,-1.21 -6.301,-12.14 -6.301,-12.14 -6.007,-0.39 -9.374,-9.42 -9.374,-9.42 0.587,-1.75 6.776,-3.7 6.776,-3.7 0.332,-13.62 -4.945,-31.3 -4.945,-31.3 v -13.83 c -1.539,-1.39 -3.289,-3.96 -3.292,-3.96"
            fill={color}
            id="path50"
          />
          <path
            d="m 725.595,1474.47 c 2.628,0 4.759,-2.13 4.759,-4.76 0,-2.63 -2.131,-4.76 -4.759,-4.76 -2.629,0 -4.759,2.13 -4.759,4.76 0,2.63 2.13,4.76 4.759,4.76"
            fill={color}
            id="path52"
          />
          <path
            d="m 548.429,1469.71 c 0,7.18 5.842,13.02 13.019,13.02 7.181,0 13.025,-5.84 13.025,-13.02 0,-2.93 -0.992,-5.72 -2.87,-8.07 -2.505,-3.15 -6.208,-4.95 -10.155,-4.95 -3.946,0 -7.643,1.81 -10.152,4.95 -1.875,2.36 -2.867,5.15 -2.867,8.07 z m -8.011,0 c 0,-2.37 0.417,-4.73 1.233,-7.03 2.974,-8.38 10.93,-14 19.797,-14 8.873,0 16.829,5.62 19.801,14 0.816,2.3 1.232,4.66 1.232,7.03 0,11.6 -9.434,21.03 -21.033,21.03 -11.593,0 -21.027,-9.43 -21.03,-21.03"
            fill={color}
            id="path54"
          />
          <path
            d="m 561.448,1474.47 c 2.631,0 4.762,-2.13 4.762,-4.76 0,-2.63 -2.131,-4.76 -4.762,-4.76 -2.63,0 -4.762,2.13 -4.762,4.76 0,2.63 2.132,4.76 4.762,4.76"
            fill={color}
            id="path56"
          />
          <path
            d="m 712.576,1469.71 c 0,7.18 5.841,13.02 13.019,13.02 7.178,0 13.019,-5.84 13.019,-13.02 0,-1.79 -0.364,-3.54 -1.088,-5.19 -2.071,-4.76 -6.755,-7.83 -11.931,-7.83 -5.176,0 -9.86,3.07 -11.931,7.83 -0.724,1.66 -1.088,3.41 -1.088,5.19 z m -8.008,0 c 0,-1.49 0.167,-3.01 0.5,-4.52 2.1,-9.57 10.734,-16.51 20.527,-16.51 9.796,0 18.426,6.94 20.527,16.51 0.332,1.51 0.5,3.03 0.5,4.52 0,11.59 -9.432,21.03 -21.027,21.03 -11.595,0 -21.027,-9.44 -21.027,-21.03"
            fill={color}
            id="path58"
          />
        </g>
      </g>
    </svg>
  );
};
