import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MainMenuPopup from "./menu";
import AccMenuPopup from "./accmenu";
import { Link, Stack} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { push } from "redux-first-history";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetOrderList, setFavorites } from "entities/orders/model";
import { ReactComponent as Logo } from "./man_logo.svg";
import { getRoles } from "entities/viewer/model";
import { useGetXlsQuery } from "entities/files/model/rtk-api";

export default function MenuAppBar() {
 // const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.viewer.user);
  const roles = useAppSelector(getRoles);
  const { data: lastXls } = useGetXlsQuery(undefined);
  //console.log(lastXls);
  // const location = useLocation();
  // const favorites = useAppSelector((state) => state.orders.favorites);

  // const favoritesHandler = () => {
  //   dispatch(setFavorites(!favorites));
  //   dispatch(push("/orders"));
  // };
  return (
    <AppBar position="relative" color="primary">
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Link component={RouterLink} to="/">
            <Logo />
          </Link>
          
          <Stack direction="row" alignItems="center" spacing={1}>
          
            <MainMenuPopup />
            {user?.roles.includes("seller") && (
              <>

                {/* <Link
                  component={RouterLink}
                  to="/orders"
                  color="white"
                  underline="none"
                  onClick={() => dispatch(resetOrderList())}
                >
                  Ordini
                </Link>
                <div>/</div> */}
                <Link
                  component={RouterLink}
                  to="/man"
                  color="white"
                  underline="none"
                >
                  MAN
                </Link>
                <div>/</div>
                {roles?.includes("used_manager") && (
                  <>

                    <Link
                      component={RouterLink}
                      to="/used"
                      color="white"
                      underline="none"
                    >
                      Usati
                    </Link>
                    <div>/</div>
                  </>

                )}


                <Link
                  component={RouterLink}
                  to="/crm"
                  color="white"
                  underline="none"
                >
                  CRM
                </Link>
              </>
            )}
            {/* {user?.roles.includes("holder") && (
              <div
                style={{ color: "white" }}
                onClick={() => favoritesHandler()}
              >
                {favorites ? <StarIcon /> : <StarOutlineIcon />}
              </div>
            )} */}
            <AccMenuPopup />
          </Stack>
        </Stack>
      </Toolbar>
      <div className={`absolute top-1 right-10 text-[10px] text-${lastXls && (lastXls[0] && (new Date().getTime() - new Date(lastXls[0].uploaded).getTime() > 5 * 24 * 60 * 60 * 1000) && 'red-500')}`}>
        AGGIORNATO: {lastXls && (lastXls[0] && new Date(lastXls[0].uploaded).toLocaleDateString("it-IT"))}
      </div>
    </AppBar>
  );
}
