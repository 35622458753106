export const Plate = ({ plate }: { plate: string }) => {
  return (
    <div className="flex text-[0.8rem]">
      <div className="border border-gray-400 bg-gray-100 rounded-sm font-bold">
        <div className="flex items-center">
          <span className="flex min-w-[8px] min-h-5 bg-blue-700 text-whitejustify-center items-end border-r text-gray-300 border-gray-400 text-center">
            {""}
          </span>
          <div className="ml-1 uppercase px-[3px]">
            {plate.length === 7 ? (
              <span>
                {plate.substring(0, 2)} {plate.substring(2, 5)}{" "}
                {plate.substring(5, 7)}
              </span>
            ) : (
              <span className="">{plate}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
