import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { ContactSearch } from "entities/crm/contacts/ui/ContactSearch";
import { useState } from "react";
import { Contact } from "entities/crm/contacts/model/types";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { useCreateLeadMutation } from "../model/qapi";
import { CompanySearch } from "entities/crm/companies/ui/CompanySearch";
import { Company } from "entities/crm/companies/model/types";
import { isEmail, isPhoneNumber } from "shared/utils";
import { origins } from "../model/constants";

interface Props {
  status: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const CreateFirstContactLead = ({
  isOpen,
  setIsOpen,
  status,
}: Props) => {
  const userId = useAppSelector((state: RootState) => state.viewer.user?.id);
  const defaultContact = {
    id: "",
    wa: false,
    name: "",
    phone: "",
    email: "",
    company: "",
    label: "",
  };
  const defaultCompany = {
    id: "",
    name: "",
    label: "",
    comune: "",
    address: "",
    site: "",
    desc: "",
  };
  const [contact, setContact] = useState<Contact>(defaultContact);
  //console.log(contact);
  const [description, setDescription] = useState("");
  const [company, setCompany] = useState<Company>(defaultCompany);
  const [leadName, setLeadName] = useState("");
  const [origin, setOrigin] = useState("");
  const [createLead] = useCreateLeadMutation();

  const roles = useAppSelector((state: RootState) => state.viewer.user?.roles);

  const filteredOrigins = roles?.includes("crm_manager") ? Object.entries(origins) : Object.entries(origins).filter(([key, value]) => !value.auto);

  const setValue = (field: string, value: string) => {
    setContact({ ...contact, [field]: value });
  };

  const setCompanyValue = (field: string, value: string) => {
    setCompany({ ...company, [field]: value });
  };

  const closeHandler = () => {
    setIsOpen(false);
    setContact(defaultContact);
  };

  const createLeadHandler = () => {
    if (
      isPhoneNumber(contact.phone) &&
      isEmail(contact.email) &&
      company.name &&
      contact.name &&
      origin 
    ) {
      createLead({
        name: leadName,
        phone: contact.phone,
        email: contact.email,
        contact_name: contact.name,
        description: description,
        origin: origin,
        status: status,
        user: userId,
        company: company.name,
      });
      setIsOpen(false);
      setContact(defaultContact);
    } else {
      if (!isPhoneNumber(contact.phone)) {
        alert("Numero di telefono non valido");
      }
      if (!isEmail(contact.email)) {
        alert("Email non valida");
      }
      if (!company.name) {
        alert("Nome azienda obbligatorio");
      }
      if (!contact.name) {
        alert("Nome contatto obbligatorio");
      }
      if (!origin) {
        alert("Origine obbligatoria");
      }
    }
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  return (
    <Dialog id="new_lead_window" open={isOpen} fullWidth scroll="body">
      <DialogContent dividers={false} sx={{ height: "430px" }}>
        <DialogContentText>
          <Stack spacing={2}>
            <div>Compila telefono, email e nome azienda</div>

            <ContactSearch
              contact={contact}
              setContact={setContact}
              field="name"
              label="Nome contatto"
              required
              value={contact.name}
              setValue={setValue}
            />
            <ContactSearch
              contact={contact}
              setContact={setContact}
              field="phone"
              label="Numero di telefono"
              required
              value={contact.phone}
              setValue={setValue}
            />
            <ContactSearch
              contact={contact}
              setContact={setContact}
              field="email"
              label="Email"
              required
              value={contact.email}
              setValue={setValue}
              helperText="Se non c'è metti la tua"
            />
            <CompanySearch
              company={company}
              setCompany={setCompany}
              field="name"
              label="Nome azienda"
              required
              value={company.name}
              setValue={setCompanyValue}
            />
            <TextField
              size="small"
              placeholder="Descrizione"
              multiline
              maxRows={2}
              onChange={handleChangeDescription}
            />
            <FormControl>
              <InputLabel id="origin-select-label">Origine</InputLabel>
              <Select
                labelId="origin-select-label"
                required
                value={origin}
            
                onChange={(event: SelectChangeEvent) => setOrigin(event.target.value)}
                label="Origine"
              >
                {filteredOrigins.map(([key, value]) => (
                  <MenuItem key={key} value={key}>{value.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler}>Cancella</Button>
        <Button
          id="create_lead_button"
          variant="contained"
          // disabled={
          //   isPhoneNumber(contact.phone) &&
          //   isEmail(contact.email) &&
          //   company.name &&
          //   contact.name
          //     ? false
          //     : true
          // }
          onClick={createLeadHandler}
        >
          CREA
        </Button>
      </DialogActions>
    </Dialog>
  );
};
