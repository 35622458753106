import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Comment, CreateCommentRequest } from './types';
import { GetCommentsParams } from './types';

export const commentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Comments'],
  endpoints: (builder) => ({
    getComments: builder.query<Comment[], GetCommentsParams>({
      query: ({ orderId = '', leadId = '' }) => ({
        url: `comments/`,
        params: { order: orderId, lead: leadId },
      }),
      providesTags: ['Comments'],
    }),

    createComment: builder.mutation<Comment, CreateCommentRequest>({
      query: (comment) => ({
        url: 'comments/',
        method: 'POST',
        body: comment,
      }),
      invalidatesTags: ['Comments'],
    }),

    deleteComment: builder.mutation<void, string>({
      query: (id) => ({
        url: `comments/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Comments'],
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
} = commentsApi; 