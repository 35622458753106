import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { ActionsList } from "./ActionsList";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getActionsList } from "../model/selectors";
import { useEffect } from "react";
import { getActions } from "../model/api";

interface OrderActionsProps {
  orderId: string;
  onClose: any;
}

export const OrderActions = ({ orderId, onClose }: OrderActionsProps) => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(getActionsList);
useEffect(() => {
  dispatch(getActions(orderId));
}, [orderId]);
  return (
    <>
      <Dialog open={true} maxWidth="lg">
        <DialogContent>
          Storia del ordine:
          {list && <ActionsList list={list} order />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>Chiudere</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
