import {
  DndContext,
  closestCenter,
  useSensors,
  useSensor,
  PointerSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";

import { CircularProgress, IconButton, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ChangeEvent, useEffect } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ImageCard } from "./ImageCard";
import { getPublicsState } from "entities/publics/model/selectors";
import {
  getPhotos,
  updatePhotosOrder,
  uploadPhotos,
} from "entities/publics/model/old-api";
import { Photo, setPhotos } from "entities/publics/model/publicsSlice";

interface Props {
  publicId: string;
}

export const PhotoManager = ({ publicId }: Props) => {
  const dispatch = useAppDispatch();
  const imageList = useAppSelector(getPublicsState).photos;
  const uploading = useAppSelector(getPublicsState).uploading;
  useEffect(() => {
    dispatch(getPhotos(publicId));
  }, []);

  const imagesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      dispatch(uploadPhotos(event.target.files, publicId));
    }
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const new_arr = (items: any) => {
        const activeIndex = items.findIndex(
          (item: Photo) => item.id === active.id
        );
        const overIndex = items.findIndex((item: Photo) => item.id === over.id);
        return arrayMove(items, activeIndex, overIndex);
      };
      dispatch(setPhotos(new_arr(imageList)));
      dispatch(updatePhotosOrder());
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  return (
    <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext items={imageList} strategy={rectSortingStrategy}>
          {imageList.map((item, index) => (
            <ImageCard image={item} index={index} />
          ))}
        </SortableContext>
      </DndContext>

      {uploading && (
        <Paper
          elevation={5}
          sx={{
            height: "150px",
            width: "200px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "5px",
          }}
        >
          <CircularProgress color="primary" />
        </Paper>
      )}

      <Paper
        elevation={5}
        sx={{
          height: "150px",
          width: "200px",
          textAlign: "center",
          lineHeight: "150px",
          margin: "5px",
        }}
      >
        <IconButton component="label">
          <input
            hidden
            accept="image/jpeg"
            multiple
            type="file"
            onChange={imagesHandler}
          />
          <AddCircleOutlineIcon sx={{ fontSize: "40px" }} />
        </IconButton>
      </Paper>
    </div>
  );
};
