import { useAppDispatch, useAppSelector } from "app/hooks";
import { useState } from "react";
import { updatePublic } from "../model/old-api";

import { FormControlLabel, Stack, Switch } from "@mui/material";
import { AdCard } from "./PublicAds/AdCard";
//import { setValue } from "../model/publicsSlice";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PreviewIcon from "@mui/icons-material/Preview";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Link, useSearchParams } from "react-router-dom";
import { PublicForm } from "./PublicForm";
//import { LeadRequestCard } from "./LeadRequestCard_c";
import { useGetPublicQuery, useUpdatePublicMutation } from '../model/api'; // Импортируйте новые хуки RTK API
import { Publics } from "../model/types";
import { openWindow } from "entities/orders/model";

interface Props {
  current: Publics;
}

export const PublicsDetails = ({ current }: Props) => {
  const dispatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [updatePublic] = useUpdatePublicMutation();

  const editHandler = () => {
    if (!current) return;
    setIsEdit(true);
  };

  const handleToggle = (field: keyof Pick<Publics, 'shop' | 'linemedia' | 'truck1'>) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!current) return;

      try {
        await updatePublic({
          id: current.id,
          [field]: event.target.checked,
        }).unwrap();
      } catch (error) {
        console.error('Failed to update public:', error);
      }
    };

  const handleOpenOrder = (order: string) => {
    setSearchParams({ order: order })
    dispatch(openWindow("newOrderWindow"))
  }

  return (
    <div>
      <div className="flex gap-3">
        <div
          className="flex flex-col gap-2 float-right"
        >
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={Boolean(current.shop)}
              onChange={handleToggle("shop")}
              aria-label="Sito"
              className="checkbox-public"
            />
            <label className="ml-2">sito</label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={Boolean(current.linemedia)}
              onChange={handleToggle("linemedia")}
              aria-label="LineMedia"
              className="checkbox-public"
            />
            <label className="ml-2">linemedia</label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={Boolean(current.truck1)}
              onChange={handleToggle("truck1")}
              aria-label="Truck1"
              className="checkbox-public"
            />
            <label className="ml-2">truck1</label>
          </div>

          <ActionButtons current={current} />
        </div>

        <Stack
          style={{ width: "80%", cursor: "pointer" }}
          onClick={editHandler}
        >
          <div>slug: {current.slug}</div>
          {current && <AdCard item={current} />}
        </Stack>
      </div>

      {current.order &&
        <div>
          <button onClick={() => handleOpenOrder(current.order)}>
            Order
          </button>
        </div>}

      {isEdit && (
        <PublicForm
          publicId={current.id}
          open={isEdit}
          setOpen={setIsEdit}
        />
      )}

    </div>
  );
};

// Выносим кнопки действий в отдельный компонент для лучшей организации
const ActionButtons = ({ current }: { current: Publics }) => {
  const shopUrl = `${process.env.REACT_APP_SHOP_URL}${current.slug}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(shopUrl);
  };

  return (
    <>
      <div>
        <a
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            color: "darkblue",
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={shopUrl}
        >
          <PreviewIcon />
        </a>
      </div>

      <div>
        <a
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            color: "darkgreen",
          }}
          href={`https://wa.me?text=${shopUrl}`}
        >
          <WhatsAppIcon />
        </a>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer",
          color: "grey",
        }}
        onClick={handleCopy}
      >
        <ContentCopyIcon />
      </div>

      <div>
        <Link
          to={`/order/${current.order}/photos`}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            color: "darkblue",
          }}
          target="_blank"
        >
          <CameraAltIcon />
        </Link>
      </div>


    </>
  );
};
