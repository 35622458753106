import { Link, Pagination, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Loader from "shared/ui/loader";
import { openWindow, ordersState, setCurrent } from "../../model";
import { Order } from "types/order";
import { useEffect, useState } from "react";
import { OrderCard } from "../OrderCard/OrderCard";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { filtersState } from "entities/filters/model/selectors";

interface OrderListProps {
  list: Order[];
}

export const OrderList = ({ list }: OrderListProps) => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector(ordersState);
  const filter = useAppSelector(filtersState).filter;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const recordsPerPage = 9;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = list.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(list.length / recordsPerPage);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const openOrderHandler = () => {
    dispatch(openWindow("newOrderWindow"));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filter, orders.type]);

  useEffect(() => {
    if (searchParams.get("order")) {
      dispatch(setCurrent(Number(searchParams.get("order"))));
      dispatch(openWindow("newOrderWindow"));
    }
  }, [searchParams]);

  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      <div
        style={{
          display: "flex",
          maxWidth: "100%",
          flexWrap: "wrap",
          gap: "15px",
          marginTop: "15px",
        }}
      >
        {currentRecords.map((item) => (
          <div
            key={item.id}
            id="list-item"
            style={{
              border: orders.last === item.id ? "2px dotted orange" : "none",
              boxSizing: "border-box",
              padding: "1px",
            }}
            onClick={() => openOrderHandler()}
          >
            <OrderCard order={item} />
          </div>
        ))}
      </div>
      {nPages > 1 && (
        <Pagination count={nPages} page={currentPage} onChange={handleChange} />
      )}

      <Typography fontSize={14}>Totale: {list.length} ordini </Typography>
    </Stack>
  );
};
