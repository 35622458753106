import { Config } from "types/order";

export const initConfig: Config = {
  id: "",
  type: "",
  top: "",
  cabine: "",
  susp: "",
  wheelbase: "",
  traction: "",
  trans: "",
  order: "",
  engine: "",
  carload: "",
  setup: "",
  color: "",
  options: "",
}; 