export const toSearchString = (params: Record<string, any>): string => {
    const searchParams = new URLSearchParams();
  
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        searchParams.append(key, value.toString());
      }
    });
  
    return searchParams.toString();
  };