import { useAppSelector } from "app/hooks";
import { getRoles } from "entities/viewer/model";
import { LeadsList } from "./LeadsList";
import { leadStatusesList } from "../model/constants";
import { useGetClosedLeadsQuery } from "../model/qapi";

import { IoMdSettings } from "react-icons/io";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Reloader } from "shared/ui/Reloader/Reloader";

interface Props {
  leadsOwner: string;
  searchString: string;
}

export const LeadBoard = ({ leadsOwner, searchString }: Props) => {
  const user = useAppSelector((state) => state.viewer.user);
  const roles = useAppSelector(getRoles);
  const [settings, setSettings] = useState(false);
  const [closed, setClosed] = useState(false);

  const closedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClosed(event.target.checked);
    if (!event.target.checked) setDtClosed(null);
  };

  const [dtClosed, setDtClosed] = useState<Dayjs | null>(null);

  const daysHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const days = (event.target as HTMLInputElement).value;
    setDtClosed(dayjs().subtract(Number(days), "day"));
  };

  const {
    data: leads_list,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  } = useGetClosedLeadsQuery({
    user: roles && roles.includes("crm_manager") ? "" : user ? user.id : "",
    dtClosed: !dtClosed ? "" : dtClosed.format("YYYY-MM-DD"),
  }, {
    pollingInterval: 10000,
  });

  useEffect(() => {
    refetch();
  }, [dtClosed]);

  const leads = isSuccess
    ? leads_list.filter(
      (item) =>
        item.company_name
          .toLowerCase()
          .includes(searchString.toLowerCase()) ||
        item.contact_str.toLowerCase().includes(searchString.toLowerCase()) ||
        //item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        (!searchString && item.company_name === "" && item.contact_str === "")
    )
    : [];
  //   useEffect(() => {
  //     dispatch(getLeads(roles?.includes("crm_manager") ? "" : user?.id));
  //   }, []);
  //if (isLoading) return <Loader />;

  return (
    <div>
      <div className="lead_screen" style={{ position: "relative" }}>
        {leadStatusesList.map(
          (leadList) =>
            leadList.showCol && (
              <LeadsList
                leads={leads}
                key={leadList.seq}
                title={leadList.title}
                canAdd={leadList.canAdd}
                status={leadList.value}
                //display={getVisibility(leadList)}
                owner={leadsOwner}
              />
            )
        )}
        <div
          style={{
            fontSize: "20px",
            position: "absolute",
            top: "8px",
            right: "10px",
            color: "grey",
            cursor: "pointer",
          }}
        >
          <IoMdSettings onClick={() => setSettings(true)} />
        </div>
      </div>

      <Dialog open={settings}>
        <DialogContent>
          <Stack direction="row" alignItems="center">
            <Checkbox checked={closed} onChange={closedHandler} />
            <div>LEAD PERSI</div>
          </Stack>
          {closed && (
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={daysHandler}
            >
              <FormControlLabel
                value="7"
                control={<Radio />}
                label="7 giorni"
              />
              <FormControlLabel
                value="30"
                control={<Radio />}
                label="30 giorni"
              />
              <FormControlLabel
                value="90"
                control={<Radio />}
                label="90 giorni"
              />
            </RadioGroup>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSettings(false)}>OK</Button>
        </DialogActions>
      </Dialog>

      {(isLoading || isFetching) && (
        <div style={{ position: "absolute", top: "79px", right: "24px" }}>
          <Reloader />
        </div>
      )}
    </div>
  );
};
