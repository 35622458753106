import { Button } from "@mui/material";
import {
  useGetUsedByIdQuery,
  useGetUsedsQuery,
  useUpdateUsedMutation,
} from "../api/api";
import { EditUsedConfigModal } from "./EditUsedConfigModal";
import { useState } from "react";
import { Used } from "../api/types";
import { TopUsedSVG } from "app/svg/topused";

interface Props {
  usedId: string;
}

export const UsedConfigCard = ({ usedId }: Props) => {
  const { data: item, isLoading, isError } = useGetUsedByIdQuery(usedId);
  const [
    updateUsed,
    { isLoading: isUdating, isError: isErrorUpdate, isSuccess },
  ] = useUpdateUsedMutation();
  const [editIsOpen, setEditIsOpen] = useState(false);

  const handleSubmit = async (data: Used) => {
    try {
      await updateUsed(data).unwrap();
    } catch (error) {
      console.error("Update failed: ", error);
    }
  };

  if (isError) return <div>Errore</div>;

  if (isLoading) return <div>Caricamento...</div>;

  return (
    <div>
      <div className="p-4 border border-gray-300 rounded shadow-sm text-xs uppercase flex flex-col items-end">
        {item.topused && <div className="mb-2"><TopUsedSVG /></div>}
        <div className="grid grid-cols-2 w-full gap-1">
          {item.model && (
            <>
              <div className="text-gray-500">MODELLO:</div>
              <div className="text-right">{item.model}</div>
            </>
          )}
          {item.category && (
            <>
              <div className="text-gray-500">CATEGORIA:</div>
              <div className="text-right">{item.category}</div>
            </>
          )}
          <>
              <div className="text-gray-500">DOVE::</div>
              <div className="text-right">{item.fis_status}</div>
            </>
          {item.dt_arrive && (
            <>
              <div className="text-gray-500">DATA DI ARRIVO:</div>
              <div className="text-right">{item.dt_arrive}</div>
            </>
          )}
          <>
            <div className="text-gray-500">ARRIVO SICURO:</div>
            <div className="text-right">{item.safe_arrival ? "SI" : "NO"}</div>
          </>
          {item.description && (
            <>
              <div className="text-gray-500">DESCRIZIONE:</div>
              <div className="text-right">{item.description}</div>
            </>
          )}
        </div>
        <div className="mt-2">
          <Button
            variant="outlined"
            sx={{ alignContent: "right" }}
            size="small"
            onClick={() => {
              setEditIsOpen(true);
            }}
          >
            MODIFICA
          </Button>
        </div>
      </div>

      <EditUsedConfigModal
        item={item}
        open={editIsOpen}
        onClose={() => setEditIsOpen(false)}
        onSave={handleSubmit}
      />
    </div>
  );
};
