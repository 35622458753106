import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { closeWindow } from "../model/servicesSlice";
import { useAppDispatch } from "app/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OrderCalc } from "entities/orders/model/calc";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useGetServiceByIdQuery, useGetServiceTypeByIdQuery } from "../model/rtk-api";
import { useSearchParams } from "react-router-dom";
import { useGetOrderByIdQuery } from "entities/orders/model/rtk-api";
import { useUpdateServiceMutation } from "../model/rtk-api";
import { quoteApi } from "entities/quotes/model/rtk-api";

const useServiceData = (serviceId: string | null) => {
  const { data: service, isLoading: serviceLoading } = useGetServiceByIdQuery(serviceId!);
  const { data: serviceType, isLoading: serviceTypeLoading } = useGetServiceTypeByIdQuery(
    service?.service_type || '', 
    { skip: !service }
  );
  const { data: order, isLoading: orderLoading } = useGetOrderByIdQuery(
    service?.order || '', 
    { skip: !service }
  );

  return {
    service,
    serviceType,
    order,
    isLoading: serviceLoading || serviceTypeLoading || orderLoading
  };
};

const usePriceCalculation = (cost: number, margin: number, marginInCost: boolean) => {
  return useMemo(() => {
    let price = cost || 0;
    if (!marginInCost) {
      price = (cost / 100) * margin + cost;
    }
    return Math.round(price);
  }, [cost, margin, marginInCost]);
};

export const ServiceFormWindow = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const serviceId = searchParams.get("service");
  const [updateServiceMutation] = useUpdateServiceMutation();

  const { service, serviceType, order, isLoading } = useServiceData(serviceId);
  const orderCalc = useMemo(() => order ? new OrderCalc(order) : null, [order]);

  const [formState, setFormState] = useState({
    desc: service?.desc || '',
    cost: service?.cost || 0,
    margin: service?.margin || 0,
    marginInCost: service?.margin_in_cost || false
  });

  const { desc, cost, margin, marginInCost } = formState;
  const price = usePriceCalculation(cost, margin, marginInCost);

  useEffect(() => {
    if (service) {
      setFormState({
        desc: service.desc || '',
        cost: service.cost || serviceType?.default_cost || 0,
        margin: service.margin || serviceType?.default_margin || 0,
        marginInCost: service.margin_in_cost || false
      });
    }
  }, [service, serviceType]);

  useEffect(() => {
    if (serviceType?.label === "margine" && orderCalc) {
      setFormState(prev => ({ ...prev, cost: orderCalc.calcCost() }));
    }
  }, [serviceType, orderCalc]);

  const handleSave = useCallback(async () => {
    dispatch(closeWindow("serviceFormWindow"));
    await updateServiceMutation({
      id: serviceId!,
      desc,
      cost,
      margin,
      service_type: serviceType?.id || '',
      price,
    }).unwrap();
    
    if (service?.quote) {
      dispatch(quoteApi.util.invalidateTags(['Quote']));
    }
  }, [serviceId, desc, cost, margin, price, serviceType, service, dispatch]);

  const handleClose = useCallback(() => {
    searchParams.delete("service");
    setSearchParams(searchParams);
    dispatch(closeWindow("serviceFormWindow"));
  }, [searchParams]);

  const updateFormState = useCallback((updates: Partial<typeof formState>) => {
    setFormState(prev => ({ ...prev, ...updates }));
  }, []);

  if (isLoading) return <Dialog open={true} fullWidth maxWidth="xs"><DialogContent>Loading...</DialogContent></Dialog>;

  return (
    <Dialog open={true} fullWidth maxWidth="xs">
      {service && (
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              size="small"
              label="Descrizione"
              value={desc}
              InputLabelProps={{ shrink: true }}
              onChange={e => updateFormState({ desc: e.target.value })}
              onFocus={e => e.target.setAttribute("autocomplete", "off")}
            />

            <TextField
              size="small"
              label="Costo"
              value={cost}
              InputLabelProps={{ shrink: true }}
              onChange={e => updateFormState({ cost: Number(e.target.value) })}
              onFocus={e => e.target.setAttribute("autocomplete", "off")}
            />

            <Stack
              style={{ width: "130px" }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <div
                style={{ color: "lightblue", cursor: "pointer" }}
                onClick={() => updateFormState({ 
                  margin: Number.isInteger(margin) ? margin - 1 : Math.floor(margin) 
                })}
              >
                <RemoveCircleIcon />
              </div>
              <div>{margin ? margin.toFixed(2) : 0}%</div>
              <div
                style={{ color: "lightblue", cursor: "pointer" }}
                onClick={() => updateFormState({ 
                  margin: Number.isInteger(margin) ? margin + 1 : Math.ceil(margin) 
                })}
              >
                <AddCircleIcon />
              </div>
            </Stack>

            <Stack direction="row" alignItems="center">
              <div>Margine nel costo</div>
              <Switch
                checked={marginInCost}
                onChange={e => {
                  const checked = e.target.checked;
                  updateFormState({ 
                    marginInCost: checked,
                    margin: checked && margin >= 100 ? 100 : margin 
                  });
                }}
              />
            </Stack>

            <TextField
              size="small"
              label="Prezzo cliente"
              disabled={marginInCost || !cost}
              value={price.toFixed(0)}
              InputLabelProps={{ shrink: true }}
              onChange={e => {
                const newPrice = Math.trunc(Number(e.target.value));
                updateFormState({ 
                  margin: ((newPrice - cost) / cost) * 100 
                });
              }}
              onFocus={e => e.target.setAttribute("autocomplete", "off")}
            />

            <div>Profitto: {Math.round((cost / 100) * margin)}</div>
          </Stack>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Chiudi</Button>
        <Button onClick={handleSave}>Salva</Button>
      </DialogActions>
    </Dialog>
  );
};
