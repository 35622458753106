import { PublicsDetails } from "./PublicsDetails";
import { useState } from "react";
import { PublicForm } from "./PublicForm";
import { Publics } from "../model/types";

interface Props {
  list: Publics[];
}

export const PublicList = ({ list }: Props) => {
  // const [isEditing, setIsEditing] = useState(false);
  // const [current, setCurrent] = useState<string | null>();
  // const openEdit = (item: Publics) => {
  //   setIsEditing(true);
  //   setCurrent(item.id);
  // };
  return (
    <>
      {list &&
        list.map((item) => (
          <div key={item.id}>
            <PublicsDetails current={item} />
          </div>
        ))}
      {/* {current && (
        <PublicForm
          publicId={current}
          open={isEditing}
          setOpen={setIsEditing}
        />
      )} */}
    </>
  );
};
