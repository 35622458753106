import { useAppDispatch } from "app/hooks";
import { useSearchParams } from "react-router-dom";
import { QuoteOrderCard } from "./QuoteOrderCard";
import { OrderStockWin } from "entities/orders/ui/OrderStockWin";
import { openWindow, setCurrent } from "entities/orders/model";
import { Stack } from "@mui/material";
import { useState } from "react";
import { NewManOrder } from "entities/man/ui/NewManOrder";
import { useGetOrdersByIdsQuery } from "entities/orders/model/rtk-api";
import { Quote } from "../model/types";
import { useAddOrderToQuoteMutation, useGetQuoteQuery } from "../model/rtk-api";

interface Props {
  quoteId: string;
}

export const QuoteOrderList = ({ quoteId }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {data:quote, isLoading:loadingQuote} = useGetQuoteQuery(quoteId);
  
  const { data: orders = [], isLoading } = useGetOrdersByIdsQuery(
    quote?.orders.map(id => id.toString()) || [], {skip: !quote}
  );

  const [addOrderToQuote] = useAddOrderToQuoteMutation();

  const handleOpenStockWindow = (stockType: string) => {
    dispatch(openWindow("orderStockWindow"));
    searchParams.set("quote", quoteId);
    searchParams.set("stock", stockType);
    setSearchParams(searchParams);
  }

  const onOrderCreated = (orderId: string) => {
    addOrderToQuote({quoteId, orderId});
    searchParams.set("order", orderId);
    setSearchParams(searchParams);
    dispatch(setCurrent(orderId));
    dispatch(openWindow("newOrderWindow"));
  }

  if (isLoading) {
    return <div>Caricamento...</div>;
  }

  return (
    <div>
      {orders.length > 0 ? (
        <Stack spacing={1}>
          {quote && quote.orders.map((item) => {
            const order = orders.find(o => o.id === item);
            return order && <QuoteOrderCard key={item} quoteId={quote.id} orderId={order.id} />;
          })}
        </Stack>
      ) : (
        <div>Non ci sono i veicoli nel preventivo</div>
      )}
      {quote &&quote.editable && (
        <div>
          <div>Aggiungi veicoli</div>
          <div className="flex items-center gap-4">
            <div
              className="btn w-40 text-center"
              onClick={() => handleOpenStockWindow("man")}
            >
              MAN IN STOCK
            </div>
            <div
              className="btn w-40 text-center"
              onClick={() => handleOpenStockWindow("used")}
            >
              USATI IN STOCK
            </div>
            <div
              className="btn w-40 text-center"
              onClick={() => setIsModalOpen(true)}
            >
              DA ORDINARE
            </div>
          </div>
        </div>
      )}
      <OrderStockWin />
      <NewManOrder isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onOrderCreated={onOrderCreated} />
    </div>
  );
};
