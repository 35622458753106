import LanguageIcon from "@mui/icons-material/Language";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ComputerIcon from "@mui/icons-material/Computer";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import { ReactComponent as SubitoLogo } from "../../icons/subito_small.svg";
import { SxProps } from "@mui/material";

interface Props {
  origin: string;
  size?: string;
}

const ICON_MAP = {
  price_request: {
    Icon: LanguageIcon,
    color: "darkblue"
  },
  subito_message: {
    Icon: SubitoLogo,
    color: undefined
  },
  subito_missed: {
    Icon: SubitoLogo,
    color: undefined
  },
  incoming_call: {
    Icon: PhoneAndroidIcon,
    color: "grey"
  },
  missed_call: {
    Icon: PhoneCallbackIcon,
    color: "grey"
  },
  truck1_message: {
    Icon: LooksOneIcon,
    color: undefined
  }
} as const;

export const OriginIcon = ({ origin, size = "20px" }: Props) => {
  const iconConfig = ICON_MAP[origin as keyof typeof ICON_MAP];
  
  const commonStyles = {
    height: size,
    width: size
  };

  const sxStyles: SxProps = {
    ...commonStyles,
    color: iconConfig?.color ?? "black"
  };

  if (iconConfig) {
    const { Icon } = iconConfig;
    return Icon === SubitoLogo ? 
      <Icon style={commonStyles} /> : 
      <Icon sx={sxStyles} />;
  }

  return <ComputerIcon sx={{ ...sxStyles, color: "black" }} />;
};
