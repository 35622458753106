import { useAppDispatch, useAppSelector } from "app/hooks";
import { getPhotos, getPublic } from "entities/publics/model/old-api";
import { getPublicsState } from "entities/publics/model/selectors";
import { PhotoList } from "entities/publics/ui/PhotoList";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const OrderPhotoPage = () => {
  const dispatch = useAppDispatch();

  const orderId = useParams().orderId;

  useEffect(() => {
    if (orderId !== undefined) dispatch(getPublic(orderId));
  }, []);
  const publics = Object.values(useAppSelector(getPublicsState).entities)[0];
  console.log(publics);
  if (publics && publics.photos && publics.photos.length > 0)
    return <PhotoList photos={publics.photos} />;
  return <>Non ci sono le foto per questo ordine</>;
};
