import { Divider, Grid, Paper, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { KeyboardEvent } from "react";
import { Order } from "types/order";
import { openWindow, setCurrent } from "../../model";
import styles from "./OrderCard.module.css";
import NewReleasesTwoToneIcon from "@mui/icons-material/NewReleasesTwoTone";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

interface OrderProps {
  order: Order;
}

export const OrderCard = ({ order }: OrderProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  //const orderId = searchParams.get("order")

  const openOrderHandler = () => {
    //dispatch(setCurrent(order.id));
    searchParams.set("order", order.id.toString());
    setSearchParams(searchParams);
    dispatch(openWindow("newOrderWindow"));
  };

  const keyDownHandler = (event: KeyboardEvent) => {
    if (event.key === "Enter") dispatch(setCurrent(order.id));
  };

  return (
    <Paper
      tabIndex={0}
      // incompleted
      sx={{
        cursor: "pointer",
        minWidth: "250px",
        //minHeight: "205px",
        bgcolor:
          (!order.list_price || !order.sale_price) && order.status === "venduto"
            ? "#f5e0d6"
            : "white",
      }}
      onClick={() => openOrderHandler()}
      onKeyDown={keyDownHandler}
    >
      <Grid container direction="column">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          className={styles.header}
          sx={{
            height: "30px",
            textAlign: "left",
            bgcolor: "primary.dark",
          }}
        >
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {order.code ? (
              <>
                {order.code.toString().split("/")[0]} /{" "}
                {order.code.toString().split("/")[1]}
              </>
            ) : (
              <>---- / -----</>
            )}
            <span
              style={{
                color: "lightblue",
                marginLeft: "20px",
              }}
            >{` ref:${order.id}`}</span>
            {/* {order.is_income_invoiced && (
              <PaidIcon sx={{ height: "20px", color: "lightblue" }} />
            )} */}
            {order.type === "SEMIRIMORCHIO" && <div>SEMIRIM. #{order.id}</div>}
            {order.type === "USATO" && <div>USATO #{order.id}</div>}
            {new Date(order.updated).toISOString().substring(0, 10) ===
              new Date().toISOString().substring(0, 10) && (
              <span>
                <NewReleasesTwoToneIcon sx={{ height: "18px" }} />
              </span>
            )}
            {!order.list_price && (
              <span>
                <ErrorOutlineOutlinedIcon
                  sx={{ height: "18px", color: "orange" }}
                />
              </span>
            )}
          </Stack>
        </Grid>

        <div style={{ minHeight: "140px " }}>
          {order.model_desc && order.model_desc.length > 1 && (
            <Grid item>
              <div className={styles.model_desc}>
                {order.model_desc.substring(0, 25)}
              </div>
            </Grid>
          )}
          <Grid
            item
            container
            direction="row"
            marginTop={1}
            marginLeft={1}
            alignItems="center"
          >
            {order.model && (
              <Grid item xs={4}>
                <div className={styles.model}>{order.model}</div>
              </Grid>
            )}

            <Grid item xs={8}>
              <div className={styles.name}>
                {order.name.toString().substring(0, 14)}
              </div>
            </Grid>
          </Grid>

          <Grid item>
            <Stack
              marginTop={1}
              marginLeft={1}
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <div className={styles.config}>
                {order.abbreviation && (
                  <span>
                    {order.abbreviation}
                    {/* {order.config.cabine}{" "}
                  {order.config.wheelbase} {order.config.traction}{" "}
                  {order.config.trans} {order.config.susp} */}
                  </span>
                )}
                {/* {!order.config && order.type === "MAN" && (
                  <span className={styles.noconf}>NON CONFIGURATO!</span>
                )} */}
              </div>
              <div className={styles.prod_status}>{order.prod_status}</div>
            </Stack>
            {order.dt_product && (
              <div className={styles.info}>
                Data produzione:{" "}
                {new Date(order.dt_product).toLocaleDateString("it-IT")}
              </div>
            )}
            {order.dt_end_edit && (
              <div className={styles.info}>
                Stop di modifiche:{" "}
                {new Date(order.dt_end_edit).toLocaleDateString("it-IT")}
              </div>
            )}

            <div className={styles.info}>
              {order.dt_supply && (
                <>
                  Fornito:{" "}
                  {new Date(order.dt_supply).toLocaleDateString("it-IT")}
                </>
              )}
            </div>

            <div className={styles.info}>
              {order.chassis && <>Telaio: {order.chassis}</>}
            </div>
          </Grid>
        </div>
        <Divider sx={{ marginTop: "3px" }} />
        <Grid item>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <div
              role="status"
              className={styles.status}
              style={{ color: order.reserved ? "darkred" : "inherit" }}
            >
              {order.status}{" "}
              <span style={{ fontSize: "0.6rem", color: "grey" }}>
                {order.dt_sale && dayjs(order.dt_sale).format(`DD/MM/YY`)}
              </span>
            </div>
            {/* {order.seller && (
              <div className={styles.seller}>
                {managers[order.seller].name
                  .split(" ")
                  .map((item) => item[0])
                  .reduce((acc, item) => acc + item, "")}
              </div>
            )} */}
            <Stack direction="row" spacing={1}>
              {order.status === "in stock" && order.quotes.length !== 0 && (
                <div className={styles.quotes}>{order.quotes.length}</div>
              )}
              {/* {order.status === "in stock" && Number(order.offers) !== 0 && (
                <div className={styles.offers}>{order.offers}</div>
              )} */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
