import { useGetSellersQuery } from "entities/managers/model/rtk-api";
import { Filters } from "../api/types";

interface FiltersFormProps {
  filters: Filters;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

export const FiltersForm: React.FC<FiltersFormProps> = ({
  filters,
  handleChange,
}) => {
  const { data } = useGetSellersQuery()

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // Reset solo il filtro venditore
    handleChange({
      ...e,
      target: {
        ...e.target,
        name: "manager",
        value: ""
      }
    });

    handleChange(e);
  };

  // Determina quali filtri mostrare in base allo status
  const showManagerFilter = filters.com_status === "venduto";
  const showLocationFilters = filters.com_status === "in stock" || filters.com_status === "";

  return (
    <div className="w-full flex flex-col gap-2">
      <div>
        <label htmlFor="com_status" className="text-xs text-gray-500">
          STATUS
        </label>
        <select
          id="com_status"
          name="com_status"
          value={filters.com_status}
          onChange={handleStatusChange}
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
        >
          <option value="">TUTTI</option>
          <option value="in stock">{"IN STOCK"}</option>
          <option value="venduto">{"VENDUTO"}</option>
          <option value="incompleto">INCOMPLETO</option>
        </select>
      </div>

      <div>
        <label htmlFor="category" className="text-xs text-gray-500">
          CATEGORIA
        </label>
        <select
          id="category"
          name="category"
          value={filters.category}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
        >
          <option value="">TUTTI</option>
          <option value="35qt">35QT</option>
          <option value="trattore">TRATTORI</option>
          <option value="motrice">MOTRICI</option>
          <option value="semirimorchio">SEMIRIMOCRHI</option>
          <option value="altro">Altro</option>
        </select>
      </div>

      {showLocationFilters && (
        <>
          <div>
            <label htmlFor="fis_status" className="text-xs text-gray-500">
              DOVE
            </label>
            <select
              id="fis_status"
              name="fis_status"
              value={filters.fis_status}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
            >
              <option value="">TUTTI</option>
              <option value="in arrivo">{"IN ARRIVO"}</option>
              <option value="in piazzale">{"IN PIAZZALE"}</option>
            </select>
          </div>

          <div>
            <label htmlFor="safe_arrival" className="text-xs text-gray-500">
              ARRIVO SUCURO
            </label>
            <select
              id="safe_arrival"
              name="safe_arrival"
              value={filters.safe_arrival}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
            >
              <option value="">TUTTI</option>
              <option value="true">SI</option>
              <option value="false">NO</option>
            </select>
          </div>
        </>
      )}

      <div>
        <label htmlFor="topused" className="text-xs text-gray-500">
          TopUsed
        </label>
        <select
          id="topused"
          name="topused"
          value={filters.topused}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
        >
          <option value="">TUTTI</option>
          <option value="true">SI</option>
          <option value="false">NO</option>
        </select>
      </div>

      {showManagerFilter && (
        <div>
          <label htmlFor="manager" className="text-xs text-gray-500">
            VENDITORE
          </label>
          <select
            id="manager"
            name="manager"
            value={filters.manager}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
          >
            <option value="">TUTTI</option>
            {data?.map((manager) => (
              <option key={manager.id} value={manager.user}>
                {manager.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div>
        <label htmlFor="ads" className="text-xs text-gray-500">
          ANNUNCIO
        </label>
        <select
          id="ads"
          name="ads"
          value={filters.ads}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
        >
          <option value="">TUTTI</option>
          <option value="true">SI</option>
          <option value="false">NO</option>
        </select>
      </div>

      <div>
        <label htmlFor="model" className="text-xs text-gray-500">
          MODELLO o TARGA
        </label>
        <input
          id="model"
          type="text"
          name="model"
          value={filters.model}
          onChange={handleChange}
          placeholder="Model"
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
        />
      </div>
    </div>
  );
};
