import React, { useState } from "react";
import { Used } from "../api/types";

interface Props {
  item: Used;
  open: boolean;
  onClose: () => void;
  onSave: (updatedItem: Used) => void;
}

export const EditUsedConfigModal: React.FC<Props> = ({
  item,
  open,
  onClose,
  onSave,
}) => {
  const [formState, setFormState] = useState(item);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
  
    setFormState((prev) => ({
      ...prev,
      [name]:
        e.target instanceof HTMLInputElement && e.target.type === "checkbox"
          ? e.target.checked
          : value === "" 
          ? null
          : value,
    }));
  };

  const handleSave = () => {
    onSave(formState);
    onClose();
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-6 space-y-4">
        <h2 className="text-lg font-bold text-gray-700">Modifica</h2>
        <div>
          <label className="text-sm text-gray-600">Modello</label>
          <input
            type="text"
            name="model"
            value={formState.model || ""}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div>
          <label className="text-sm text-gray-600">Categoria</label>
          <select
            name="category"
            value={formState.category || ""}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          >
            <option value="">Seleziona categoria</option>
            <option value="35qt">{"35qt"}</option>
            <option value="trattore">{"trattore"}</option>
            <option value="motrice">{"motrice"}</option>
            <option value="semirimorchio">Semirimorchio</option>
            <option value="altro">Altro</option>
          </select>
        </div>

        <div>
          <label className="text-sm text-gray-600">Descrizione</label>
          <textarea
            name="description"
            value={formState.description || ""}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div>
          <label className="text-sm text-gray-600">Data di Arrivo</label>
          <input
            type="date"
            name="dt_arrive"
            value={formState.dt_arrive || ""}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          />
        </div>
        <div className="flex gap-2 items-center">
          <label className="text-sm text-gray-600">Arrivo Sicuro</label>
          <input
            type="checkbox"
            name="safe_arrival"
            checked={formState.safe_arrival}
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                safe_arrival: e.target.checked,
              }))
            }
            className="h-4 w-4 border border-gray-300 rounded"
          />
        </div>

        <div className="flex gap-2 items-center">
          <label className="text-sm text-gray-600">MAN TopUsed</label>
          <input
            type="checkbox"
            name="topused"
            checked={formState.topused}
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                topused: e.target.checked,
              }))
            }
            className="h-4 w-4 border border-gray-300 rounded"
          />
        </div>

        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
          >
            Indietro
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Salva
          </button>
        </div>
      </div>
    </div>
  );
};
