import { Service } from "./types";


type ServiceCalcType = {
  cost: number;
  margin: number;
  margin_in_cost: boolean;
  price: number;
};

export class ServiceCalc implements ServiceCalcType {
  cost;
  margin;
  margin_in_cost;
  price;
  constructor(service: Service) {
    if (!service) {
      throw new Error("Service is required");
    }
    this.cost = service.cost;
    this.margin = service.margin;
    this.margin_in_cost = service.margin_in_cost;
    this.price = service.price;
  }

  calcPrice() {
    if (this.margin_in_cost) {
      return this.cost * (1 + this.margin / 100);
    } else {
      return this.cost + this.margin;
    }
  }

  calcProfit = () => {
    let profit = 0;
    if (this.cost) {
      profit = (this.cost / 100) * this.margin;
    }
    return profit || 0;
  };
}
