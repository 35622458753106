import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Photo, Publics } from './types';
import { Order } from 'types/order';


interface PhotoSequence {
  id: string;
  sequence: number;
}

export const publicsApi = createApi({
  reducerPath: 'publicsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/public/',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Public', 'Photos'],
  endpoints: (builder) => ({
    getPublics: builder.query<Publics[], string>({
      query: (searchString) => `adm?${searchString}`,
      providesTags: ['Public'],
    }),

    getPublic: builder.query<Publics, string>({
      query: (publicId) => `adm/${publicId}`,
      providesTags: ['Public'],
    }),

    getPublicByOrderId: builder.query<Publics, string>({
      query: (orderId) => `adm/${orderId}/get_public_by_order_id`,
      providesTags: ['Public'],
    }),

    createPublic: builder.mutation<Publics, Order>({
      query: (order) => ({
        url: 'adm/',
        method: 'POST',
        body: {
          order: order.id,
        },
      }),
      invalidatesTags: ['Public'],
    }),

    createPermPublic: builder.mutation<Publics, string>({
      query: (userId) => ({
        url: 'adm/',
        method: 'POST',
        body: {
          created_by: userId,
          title: 'Annuncio permanente',
          subtitle: 'sottotitolo',
          desc: 'descrizione',
          permanent: true,
        },
      }),
      invalidatesTags: ['Public'],
    }),

    updatePublic: builder.mutation<Publics, Partial<Publics>>({
      query: (public_) => ({
        url: `adm/${public_.id}/`,
        method: 'PATCH',
        body: public_,
      }),
      invalidatesTags: ['Public'],
    }),

    getPhotos: builder.query<Photo[], string>({
      query: (publicId) => `photos/?public=${publicId}`,
      providesTags: ['Photos'],
    }),

    uploadPhotos: builder.mutation<void, { files: FileList; publicId: string }>({
      query: ({ files, publicId }) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('file', files[i]);
        }
        formData.append('public', publicId);
        return {
          url: 'photos/',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Photos'],
    }),

    updatePhotosOrder: builder.mutation<void, PhotoSequence[]>({
      query: (updates) => ({
        url: 'update-photo-sequence/',
        method: 'POST',
        body: updates,
      }),
      invalidatesTags: ['Photos'],
    }),

    deletePhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `photos/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Photos'],
    }),
  }),
});

export const {
  useGetPublicsQuery,
  useGetPublicQuery,
  useCreatePublicMutation,
  useCreatePermPublicMutation,
  useUpdatePublicMutation,
  useGetPhotosQuery,
  useUploadPhotosMutation,
  useUpdatePhotosOrderMutation,
  useDeletePhotoMutation,
  useGetPublicByOrderIdQuery,
} = publicsApi; 