import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ManOrder } from './types';

export const manOrderApi = createApi({
  reducerPath: 'manOrderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/man',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('authorization', `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['ManOrders', 'ManOrder', 'ManConfig'],
  endpoints: (builder) => ({
    getManOrders: builder.query({
      query: (search_string) => `orders?${search_string}`,
      providesTags: ['ManOrders'],
    }),
    getManOrderById: builder.query<ManOrder, number>({
      query: (id) => `orders/${id}`,
      providesTags: ['ManOrder'],

    }),
    addManOrder: builder.mutation<ManOrder, ManOrder>({
      query: (order) => ({
        url: 'orders/',
        method: 'POST',
        body: order,
      }),
      invalidatesTags: ['ManOrders'],
    }),
    updateManOrder: builder.mutation<ManOrder, ManOrder>({
      query: (order) => ({
        url: `orders/${order.id}/`,
        method: 'PUT',
        body: order,
      }),
      invalidatesTags: ['ManOrders', 'ManOrder'],
    }),
    removeManOrder: builder.mutation<void, number>({
      query: (id) => ({
        url: `orders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ManOrders'],
    }),
    getManConfig: builder.query({
      query: (id) => `config/${id}`,
      providesTags: ['ManConfig'],
    }),
    updateManConfig: builder.mutation({
      query: (config) => ({
        url: `config/${config.id}/`,
        method: 'PUT',
        body: config,
      }),
      invalidatesTags: ['ManConfig', 'ManOrders'],
    }),
  }),
});

export const {
  useGetManOrdersQuery,
  useGetManOrderByIdQuery,
  useUpdateManOrderMutation,
  useAddManOrderMutation,
  useGetManConfigQuery,
  useUpdateManConfigMutation,
  useRemoveManOrderMutation,
} = manOrderApi;
