import { Button, Stack } from "@mui/material";
import { useUploadFileMutation } from "entities/files/model/rtk-api";
import { FileList } from "./FileList";
import { ChangeEvent, useState } from "react";
import Loader from "shared/ui/loader";
import { FileAnalyzer } from "FileAnalyzer/FileAnalyzer";
//import { FileAnalyzer } from "entities/fileAnalyzer/ui/FileAnalyzer";


export const OrderFiles = ({ orderId }: { orderId: string }) => {
  //const { data: files, isLoading } = useGetFilesQuery(orderId);
  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();
  const [AnalyzerIsOpen, analyzerSetIsOpen] = useState(false);
  const filesHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      await uploadFile({ files: event.target.files, orderId });
    }
  };

  if (isUploading) return <Loader />;

  return (
    <Stack direction="column">
      <FileList orderId={orderId} />
      <div className="flex flex-row gap-2 items-center mt-4">
        <Button
          sx={{ maxWidth: "200px" }}
          variant="contained"
          component="label"
        >
          Carica documento
          <input
            hidden
            accept="pdf/*"
            multiple
            type="file"
            onChange={filesHandler}
          />
        </Button>
        <Button variant="outlined" onClick={() => analyzerSetIsOpen(true)}>Riconoscimento file</Button>
      </div>
       <FileAnalyzer isOpen={AnalyzerIsOpen} onClose={() => analyzerSetIsOpen(false)} /> 
    </Stack>
  );
};
