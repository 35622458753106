import { useState, useEffect } from "react";
import { useGetPublicQuery, useUpdatePublicMutation } from "../model/api";
import { PhotoManager } from "./PhotoManager";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { ordersList } from "entities/orders/model";
import dayjs from "dayjs";
import { getRoles } from "entities/viewer/model";
import { AdConfig } from "./AdConfig";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Publics } from "../model/types";
import axios from "axios";
import { Order } from "types/order";

interface Props {
  publicId: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  //public_: Publics;
}

export const PublicForm = ({ publicId, open, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const { data: publicData } = useGetPublicQuery(publicId);
  const [search, setSearch] = useState<string>("");
  const [searchedOrders, setSearchedOrders] = useState<Order[]>();
  const [searchError, setSearchError] = useState<string>("");

  const [formData, setFormData] = useState<Publics>(() => {
    if (publicData) {
      return publicData;
    }
    return {
      id: publicId,
      title: "",
      subtitle: "",
      desc: "",
      slug: "",
      price: "",
      type: "",
      permanent: false,
      show_price: false,
      order: "",
      abbr: "",
    };
  });

  useEffect(() => {
    if (publicData) {
      setFormData(publicData);
    }
  }, [publicData]);

  const [updatePublic, { isLoading }] = useUpdatePublicMutation();
  const orders = useAppSelector(ordersList);
  const roles = useAppSelector(getRoles);

  const handleTextChange =
    (field: keyof Publics) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prev) => ({
          ...prev,
          [field]: event.target.value,
        }));
      };

  const handleSwitchChange =
    (field: keyof Publics) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [field]: event.target.checked,
      }));
    };

  const handleSelectChange =
    (field: keyof Publics) => (event: SelectChangeEvent) => {
      setFormData((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

  const savePublicHandler = async () => {
    try {
      await updatePublic(formData).unwrap();
      setOpen(false);
    } catch (error) {
      console.error("Failed to save public:", error);
      alert("Failed to save public");
    }
  };

  const searchOrder = async (str: string) => {
    await axios
      .get(`/api/order/search/?search=${str}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        setSearchedOrders(data);
      })
      .catch((er) => {
        console.log(er.message);
        setSearchError("Error");
      });
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogContent>
        <Stack direction="row" spacing={3}>
          <Stack spacing={3}>
            {roles?.includes("public_manager") && (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.permanent}
                      onChange={handleSwitchChange("permanent")}
                      inputProps={{ "aria-label": "Permanente" }}
                    />
                  }
                  label="Permanente"
                />
                {formData.permanent && (
                  <>
                    <div>
                      <TextField
                        sx={{ width: "450px" }}
                        value={formData.slug}
                        label="slug"
                        size="small"
                        onChange={handleTextChange("slug")}
                      />
                    </div>

                    <div>
                      <label htmlFor="search" className="text-xs text-gray-500">
                        Ref ordine
                      </label>
                      <input
                        id="search"
                        value={search}
                        type="text"
                        onChange={(e) => setSearch(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                      />
                      <Button onClick={() => searchOrder(search + " in stock")}>
                        Cerca
                      </Button>
                    </div>

                    {searchedOrders && searchedOrders?.length > 0 && (
                      <FormControl fullWidth>
                        <InputLabel id="select-label">Ordine</InputLabel>
                        <Select
                          style={{ fontSize: "12px" }}
                          size="small"
                          labelId="select-label"
                          value={formData.order}
                          label="Ordine"
                          onChange={handleSelectChange("order")}
                        >
                          {searchedOrders?.map((item) => (
                            <MenuItem
                              key={item.id}
                              style={{ fontSize: "12px" }}
                              value={item.id}
                            >
                              ref:{item.id} {item.model_desc || ""} {item.code || ""} {item.abbreviation || ""}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </>
                )}
              </>
            )}

            <TextField
              sx={{ width: "450px" }}
              value={formData.title}
              label="Titolo"
              size="small"
              onChange={handleTextChange("title")}
            />

            <div>{formData.abbr}</div>

            <TextField
              sx={{ width: "450px" }}
              value={formData.subtitle}
              label="Sottotitolo"
              size="small"
              onChange={handleTextChange("subtitle")}
            />

            {formData.config && <AdConfig config={formData.config} />}

            <TextField
              sx={{ width: "450px" }}
              value={formData.desc}
              label="Descrizione"
              multiline
              maxRows={12}
              size="small"
              onChange={handleTextChange("desc")}
            />

            {formData.sticker?.state === "USATO" && (
              <TextField
                sx={{ width: "450px" }}
                value={formData.type}
                label="Type (camion o furgone):"
                multiline
                maxRows={12}
                size="small"
                onChange={handleTextChange("type")}
              />
            )}

            <TextField
              sx={{ width: "450px" }}
              value={formData.price}
              label="Prezzo"
              multiline
              maxRows={12}
              size="small"
              onChange={handleTextChange("price")}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(formData.show_price)}
                  onChange={handleSwitchChange("show_price")}
                  inputProps={{ "aria-label": "Mostra prezzo" }}
                />
              }
              label="Mostra prezzo"
            />
          </Stack>
          <div>
            <PhotoManager publicId={formData.id} />
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} disabled={isLoading}>
          CHIUDI
        </Button>
        <Button onClick={savePublicHandler} disabled={isLoading}>
          {isLoading ? "Salvando..." : "Salva"}
        </Button>
      </DialogActions>
    </Dialog>
  );


};

