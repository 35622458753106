import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import axios from "axios";
import { sendAction } from "entities/actions/model/api";
import { Contact } from "entities/crm/contacts/model/types";
import { useEffect, useState } from "react";
import { AttachWindow } from "./AttachWindow";
import { OrderFile } from "entities/files/model";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Manager } from "entities/managers/model/types";
import { useGetCurrentManagerQuery } from "entities/managers/model/rtk-api";

export type Email = {
  subject: string;
  message: string;
  to: string;
  from: string;
  bcc?: string;
  files?: string[];
  template: string;
};

interface Props {
  sender?: Manager;
  contact: Contact;
  subject: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  messageText?: string;
  action?: { type: "lead" | "order"; id: string };
  files?: string[]; //attached
  filesToAttach?: OrderFile[]; // possible attached
}

export const SendEmailForm = ({
  sender,
  contact,
  subject,
  messageText = "",
  isOpen,
  setIsOpen,
  action,
  files,
  filesToAttach,
}: Props) => {
  const [msg, setMsg] = useState("");
  const [isSent, setIsSent] = useState(true);
  const [openResult, setOpenResult] = useState(false);

  const [attachedFiles, setAttachedFiles] = useState(files ? files : []);
  const addFilesToAttach = (files: string[]) => {
    setAttachedFiles([
      ...attachedFiles,
      ...files.filter((item) => !attachedFiles.includes(item)),
    ]);
  };

  const [attachOpen, setAttachOpen] = useState(false);

  const [sendCopy, setSendCopy] = useState(true);
  const setCopyHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendCopy(event.target.checked);
  };

  const { data: currentManager } = useGetCurrentManagerQuery(undefined);
  const from = sender ? sender : currentManager;
  const dispatch = useAppDispatch();
  //const emailSign = `\n${from.name},\nvenditore Alpiservice SRL \n${from.email},  \ncell: ${from.cell}`;

  useEffect(() => {
    setMsg(messageText);
  }, [messageText]);

  const sendEmailHandler = () => {
    if (!from?.email) return;
    dispatch(
      sendEmail({
        from: from.email,
        subject: subject,
        to: contact.email,
        message: msg, // + emailSign,
        bcc: sendCopy ? from.email : "",
        files: attachedFiles,
        template: "client_email",
      })
    );

    setOpenResult(true);
  };

  const messageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMsg(event.target.value);
  };

  const cancelHandler = () => {
    setIsOpen(false);
    setAttachedFiles([]);
  };

  const closeResultHandler = () => {
    cancelHandler();
    setOpenResult(false);
  };

  const sendEmail = (email: Email) => async () => {
    await axios
      .post(`/api/mail/send-mail/`, email, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const status = response.status;
        if (status === 200) {
          setIsSent(true);
          if (action)
            dispatch(
              sendAction({
                type: action.type,
                id: action.id,
                verb: `${subject} è inviato su ${contact.email}`,
              })
            );
        } else {
          setIsSent(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsSent(false);
      });
  };

  return (
    <>
      <Dialog open={isOpen} maxWidth="md" fullWidth>
        <DialogContent>
          {contact && contact.email ? (
            <>
              <Stack spacing={1}>
                <div style={{ fontWeight: "lighter" }}>
                  A: {contact.name} ({contact.email}){" "}
                </div>
                <div style={{ fontWeight: "lighter" }}>Da: {from?.email}</div>
                <div style={{ fontWeight: "lighter" }}>Oggetto: {subject}</div>
              </Stack>
              <TextField
                size="small"
                defaultValue={msg}
                multiline
                rows={8}
                sx={{ width: "100%", marginTop: "20px" }}
                placeholder="Messaggio..."
                onChange={messageHandler}
              />
              {/* <div style={{ marginTop: "20px" }}>{emailSign}</div> */}

              {attachedFiles && attachedFiles.length > 0 && (
                <Stack marginTop={2} spacing={1} marginBottom={1}>
                  {attachedFiles.map((file) => (
                    <Link href={file} target="_blank" key={file}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <InsertDriveFileIcon sx={{ color: "grey" }} />
                        <div>{file.split("/").slice(-1)}</div>
                      </Stack>
                    </Link>
                  ))}
                </Stack>
              )}
              {filesToAttach && (
                <Button variant="contained" onClick={() => setAttachOpen(true)}>
                  agg. documento
                </Button>
              )}
              <div
                style={{
                  width: "100%",
                  textAlign: "right",
                  fontWeight: "lighter",
                  color: "grey",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      aria-label="test"
                      checked={sendCopy}
                      onChange={setCopyHandler}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="COPIA AL MITTENTE"
                  labelPlacement="start"
                />
              </div>
            </>
          ) : (
            <div>
              Questo contatto non ha la email valida. Controlla il contatto.
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelHandler()}>CANCELLA</Button>
          {contact && contact.email && (
            <Button
              variant="contained"
              sx={{ bgcolor: "darkred" }}
              onClick={() => sendEmailHandler()}
            >
              INVIA
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {attachOpen && filesToAttach && (
        <AttachWindow
          open={attachOpen}
          setOpen={setAttachOpen}
          filesToAttach={filesToAttach}
          setAttachedFiles={addFilesToAttach}
          attachedFiles={attachedFiles}
        />
      )}
      <Dialog open={openResult}>
        <DialogContent>
          {isSent ? (
            <div>Messaggio è stato inviato corettamente</div>
          ) : (
            <div style={{ color: "darkred" }}>
              Messaggio non è stato inviato. Errore.
            </div>
          )}
        </DialogContent>{" "}
        <DialogActions>
          <Button onClick={() => closeResultHandler()}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
