import { AgGridReact } from 'ag-grid-react'
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community';
import Loader from "shared/ui/loader/loader";
import { useGetCompaniesQuery } from "../model/rtkapi";
import { useMemo, useState } from 'react';
import { Pagination } from 'shared/ui/Pagination';

const PAGE_SIZE = 20;

ModuleRegistry.registerModules([AllCommunityModule]);
export const CompanyList = () => {
  const [searchString, setSearchString] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useGetCompaniesQuery({ 
    searchString, 
    page: currentPage.toString() 
  });
  const companies = data?.results;

  console.log(companies);

  const defaultColDef = useMemo(() => { 
    return {
          width: 150,
          cellStyle: { fontWeight: 'normal' },
      };
  }, []);

  const colDefs = [
    { headerName: 'ID', field: 'id' },
    { headerName: 'Name', field: 'name' },
    { headerName: 'Comune', field: 'comune' },
    { headerName: 'Leads', field: 'leads' },
    { headerName: 'Contacts', field: 'contacts' },
    { headerName: 'Vehicles', field: 'vehicles' },
  ];


  if (isLoading) return <Loader />;
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full max-w-md">
        <input
          type="text"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="Cerca aziende..."
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
      
      <div className="ag-theme-alpine" style={{ height: 800, width: 1200 }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          rowData={companies}
          columnDefs={colDefs}
        />
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(data?.count / PAGE_SIZE)}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};
