import { createSlice } from "@reduxjs/toolkit";
import { arrToMap } from "shared/utils";
import { Service, ServiceType } from "./types";



type Windows = {
  serviceFormWindow: boolean;
  serviceCreateWindow: boolean;
};

interface ServicesSlice {
  loading: boolean;
  error: boolean;
  entities: { [id: number]: Service };
  serviceTypes: { [id: number]: ServiceType };
  current: number | null;
  windows: Windows;
  currentOrderId: string;
}

const initialState: ServicesSlice = {
  loading: false,
  error: false,
  entities: {},
  serviceTypes: {},
  current: null,
  windows: {
    serviceFormWindow: false,
    serviceCreateWindow: false,
  },
  currentOrderId: "",
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload as boolean;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = arrToMap(payload);
      state.loading = false;
      state.error = false;
    },
    setCurrentService: (state, { payload }) => {
      state.current = payload;
    },
    setEntity: (state, { payload }) => {
      state.entities[payload.id] = payload;
      //state.current = payload.id;
    },

    removeEntity: (state, { payload }) => {
      delete state.entities[payload];
    },
    setServiceTypes: (state, { payload }) => {
      state.serviceTypes = arrToMap(payload);
    },
    openWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = true;
    },
    closeWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = false;
    },
    setCurrentOrder: (state, { payload }) => {
      state.currentOrderId = payload;
    },
    resetServices: () => initialState,
  },
});

export const {
  setLoading,
  setError,
  setEntities,
  setEntity,
  setServiceTypes,
  openWindow,
  closeWindow,
  removeEntity,
  setCurrentService,
  setCurrentOrder,
} = servicesSlice.actions;

export default servicesSlice.reducer;
