import { Paper } from "@mui/material";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { useAppDispatch } from "app/hooks";
import styles from "./ImageCard.module.css";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Photo, remPhoto } from "entities/publics/model/publicsSlice";
import { deletePhoto } from "entities/publics/model/old-api";

export interface ImageCardProps {
  image: Photo;
  index: number;
}

export const ImageCard = ({ image, index }: ImageCardProps) => {
  const dispatch = useAppDispatch();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const imgDeleteHandler = (id: string) => {
    dispatch(deletePhoto(id));
    dispatch(remPhoto(id));
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Paper
        className={styles.paper}
        elevation={5}
        sx={{
          height: "150px",
          width: "200px",
          position: "relative",
          marginX: "10px",
          marginY: "5px",
        }}
      >
        <img
          src={`${image.image}`}
          alt="alt"
          loading="lazy"
          className={styles.img}
        />
        <div
          className={styles.delete}
          onClick={() => imgDeleteHandler(image.id)}
        >
          <HighlightOffSharpIcon />
        </div>
        <div className={styles.order}>{index + 1}</div>
        {/* {index === 0 && <div className={styles.cover}>COVER</div>} */}
      </Paper>
    </div>
  );
};
