import { useState } from "react";
import InvoiceRenderer from "./components/Renderers/InvoiceRenderer";
import OrderRenderer from "./components/Renderers/OrderRenderer";
import { readFileAndDetectContent } from "./fileHandlers/fileReader";

interface FileAnalyzerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FileAnalyzer: React.FC<FileAnalyzerProps> = ({ isOpen, onClose }) => {
  const [fileData, setFileData] = useState<any | null>(null);
  const [contentType, setContentType] = useState<string | null>(null);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const { contentType, data } = await readFileAndDetectContent(file);
    setContentType(contentType);
    setFileData(data);
  };

  const renderContent = () => {
    switch (contentType) {
      case 'invoice':
        return <InvoiceRenderer data={fileData} />;
      case 'order':
        return <OrderRenderer data={fileData} />;
      default:
        return <p>Unsupported content type</p>;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Chiudi"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        
        <div className="mt-4">
          <input
            type="file"
            onChange={handleFileUpload}
            className="border p-2 rounded-lg w-full"
          />
          <div className="mt-4">{fileData && renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

