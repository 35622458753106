import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useDeleteQuoteMutation } from "../model/rtk-api";
import { closeWindow } from "app/windows/windowsSlice";

interface Props {
  quoteId: string;
}

export const QuoteDelWindow = ({ quoteId }: Props) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.windows.quoteDelWindow);
  const [deleteQuote] = useDeleteQuoteMutation();
  const deleteHandler = () => {
    deleteQuote(quoteId);
    dispatch(closeWindow("quoteDelWindow"));
  };
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        Sei sicuro di voler eliminare questo preventivo?{" "}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => dispatch(closeWindow("quoteDelWindow"))}
        >
          TORNA
        </Button>
        <Button variant="outlined" onClick={() => deleteHandler()}>
          Sono sicuro
        </Button>
      </DialogActions>
    </Dialog>
  );
};
