import { Button, Link, Stack } from "@mui/material";
import { SendPricePanel } from "./SendPricePanel";
import { useAppDispatch } from "app/hooks";
import { useGetLeadByIdQuery, useUpdateLeadMutation } from "../model/qapi";
import { useGetQuotesQuery } from "entities/quotes/model/rtk-api";
import { useGetContactByIdQuery } from "entities/crm/contacts/model/rtkapi";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { NewLeadAction } from "./NewLeadAction";

interface Props {
  leadId: string;
}

export const LeadActions = ({ leadId }: Props) => {
  const dispatch = useAppDispatch();
  const { data: lead } = useGetLeadByIdQuery(leadId);

  const { data: quotes } = useGetQuotesQuery({ leadId: leadId });

  const [updateLead] = useUpdateLeadMutation();

  const passToNextStatusHandler = (status: string) => {
    updateLead({
      id: leadId,
      status: status,
    });
  };

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <Stack direction="column" spacing={2} alignItems="center">
        {lead && lead.status === "new" && !lead.result && (
          <>
            <NewLeadAction leadId={leadId} />
            <Button
              variant="outlined"
              onClick={() => passToNextStatusHandler("discussion")}
            >
              Ho fatto una comunicazione e sto aspettando la risposta
            </Button>
          </>
        )}
        {lead && lead.status === "discussion" && quotes && quotes.length > 0 && (
          <>
            <div>
              <Button
                variant="outlined"
                onClick={() => passToNextStatusHandler("decision")}
              >
                Almeno un preventivo è comunicato al cliente
              </Button>
            </div>
          </>
        )}

        {lead && lead.status === "decision" && (
          <>
            Per passare allo stato "Firma contratto" devi accettare almeno un
            preventivo
          </>
        )}
      </Stack>
    </div>
  );
};
