import React from "react";

const FurgonSVG = ({color}:{color?:string}) => {
  return (
  <svg
    width="30px"
    height="15px"
    viewBox="0 0 55.950153 25.338879"
    version="1.1"
    id="svg443"
    fill="#22222"
  >
    <defs id="defs440" />
    <g id="layer1" transform="translate(-77.341276,-39.547388)">
    <path
       d="m 133.29143,60.31978 c 0,0 -1.02907,1.414804 -3.34427,1.414804 h -14.59851 c 0,0 -1.54347,0.192867 -1.54347,-1.414804 V 46.557207 c 0,0 -0.0642,-1.350335 1.09327,-1.350335 h 5.01614 c 0,0 1.09354,0 1.99368,1.286136 l 4.56621,5.080616 5.65921,2.829606 c 0,0 1.15774,0.385733 1.15774,3.987348 v 1.929202"
       fill={color}
       id="path56" />
    <path
       d="M 115.73465,48.454582 V 47.48971 c 0,0 -0.0324,-0.964602 1.0609,-0.964602 h 3.15141 c 0,0 1.0288,-0.0321 1.479,0.51467 l 4.37308,4.82328 c 0,0 1.28641,1.189568 0,1.189568 h -2.25101 c 0,0 -3.18324,0.128398 -3.8584,-0.996971 h -2.50808 c 0,0 -1.44717,2.67e-4 -1.44717,-1.253767 l 2.4e-4,-2.347306"
       fill="#ffffff"
       id="path58" />
    <path
       d="m 116.86002,54.563998 c 0,0.142155 -0.11518,0.257335 -0.25734,0.257335 h -1.28613 c -0.14216,0 -0.25734,-0.11518 -0.25734,-0.257335 V 53.73157 c 0,-0.142155 0.11518,-0.257335 0.25734,-0.257335 h 1.28613 c 0.14216,0 0.25734,0.11518 0.25734,0.257335 v 0.832428"
       fill="#ffffff"
       id="path60" />
    <path
       d="m 130.99322,53.832994 c 0,0 -0.8532,-0.08362 -0.42431,0.527348 l 1.5219,2.014982 c 0,0 1.03419,0.944102 1.10136,0.364964 0,0 -0.11302,-1.488175 -0.90742,-2.244804 l -1.29153,-0.66249"
       fill="#ffffff"
       id="path62" />
    <path
       d="m 113.9975,44.692202 h 2.50862 c 0,0 0.9004,-0.0642 0.9004,-0.900133 V 41.60526 c 0,0 0,-1.09327 -1.25404,-1.09327 H 113.9975 V 39.547388 H 78.434276 c 0,0 -1.093,0 -1.093,1.221937 v 17.363917 h 5.016146 v 1.607671 H 79.84935 l -1.286406,0.707536 v 0.643066 h 3.73001 l 0.51467,0.650353 h 2.122071 l 0.964602,-1.22922 h 1.479272 v -0.707269 h 26.624471 l -4.8e-4,-15.113177"
       fill={color}
       id="path64" />
    <path
       d="m 97.373763,61.248507 c 0,1.904656 -1.544011,3.448939 -3.448668,3.448939 -1.904928,0 -3.448938,-1.544283 -3.448938,-3.448939 0,-1.904927 1.54401,-3.448939 3.448938,-3.448939 1.904657,0 3.448668,1.544012 3.448668,3.448939"
       fill={color}
       id="path66" />
    <path
       d="m 97.373763,61.248507 c 0,1.904656 -1.544011,3.448939 -3.448668,3.448939 -1.904928,0 -3.448938,-1.544283 -3.448938,-3.448939 0,-1.904927 1.54401,-3.448939 3.448938,-3.448939 1.904657,0 3.448668,1.544012 3.448668,3.448939 z"
       fill="none"
       id="path68" />
    <path
       d="m 129.15762,61.248507 c 0,1.904656 -1.54401,3.448939 -3.44867,3.448939 -1.90493,0 -3.44894,-1.544283 -3.44894,-3.448939 0,-1.904927 1.54401,-3.448939 3.44894,-3.448939 1.90466,0 3.44867,1.544012 3.44867,3.448939"
       fill={color}
       id="path70" />
    <path
       d="m 129.15762,61.248507 c 0,1.904656 -1.54401,3.448939 -3.44867,3.448939 -1.90493,0 -3.44894,-1.544283 -3.44894,-3.448939 0,-1.904927 1.54401,-3.448939 3.44894,-3.448939 1.90466,0 3.44867,1.544012 3.44867,3.448939 z"
       fill="none"
       id="path72" />
    <path
       d="m 109.33553,60.641315 c 0,0.142155 -0.11518,0.257335 -0.25707,0.257335 h -7.13848 c -0.14216,0 -0.25734,-0.11518 -0.25734,-0.257335 v -1.061172 c 0,-0.141883 0.11518,-0.257063 0.25734,-0.257063 h 7.13848 c 0.14189,0 0.25707,0.11518 0.25707,0.257063 v 1.061172"
       fill={color}
       id="path74" />
    </g>
  </svg>
)};

export default FurgonSVG;
