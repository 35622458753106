
import { Filters } from "../model/types";
import { useState } from "react";
import { manConfig } from "../model/config";
import { useGetSellersQuery } from "entities/managers/model/rtk-api";

interface FiltersFormProps {
  filters: Filters;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  resetConfigFilters: () => void;
}

export const FiltersForm: React.FC<FiltersFormProps> = ({
  filters,
  handleChange,
  resetConfigFilters,
}) => {

  const [showConfigFilters, setShowConfigFilters] = useState(false);

  const { data: sellers } = useGetSellersQuery();

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName?: string
  ) => {
    handleChange({
      ...e,
      target: {
        ...e.target,
        name: fieldName || e.target.name,
        value: e.target.value
      }
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({
      ...e,
      target: {
        ...e.target,
        name: e.target.name,
        value: e.target.checked.toString()
      }
    });
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleFieldChange({ ...e, target: { ...e.target, value: "" } }, "seller");
    handleFieldChange(e);
  };

  const toggleConfigFilters = () => {
    if (showConfigFilters) {
      resetConfigFilters();
    }
    setShowConfigFilters(!showConfigFilters);
  };

  return (
    <div className="w-full flex flex-col gap-2">

      <div>
        <label htmlFor="search" className="text-xs text-gray-500">
          NOME / AUFTRAG / #(ref)
        </label>
        <input
          id="search"
          name="search"
          type="text"
          onChange={(e) => handleFieldChange(e)}
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
        />
      </div>

      <div>
        <label htmlFor="com_status" className="text-xs text-gray-500">
          STATUS
        </label>
        <select
          id="com_status"
          name="com_status"
          value={filters.com_status}
          onChange={handleStatusChange}
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm bg-orange-50"
        >
          <option value="">TUTTI</option>
          <option value="in stock">{"IN STOCK"}</option>
          <option value="venduto">{"VENDUTO"}</option>
          <option value="incompleto">INCOMPLETO</option>
          <option value="preventivo">{"PREVENTIVO"}</option>
        </select>
      </div>

      <div className="flex items-center gap-2">
        <div className="text-xs text-gray-500">
          SOLO TRUCKS
        </div>
        <div>
          <input
            type="checkbox"
            name="trucks"
            onChange={handleCheckboxChange}
            className="w-full"
          />
        </div>
      </div>

      <div>
        <label htmlFor="model" className="text-xs text-gray-500">
          MODELLO
        </label>
        <select
          id="model"
          name="model"
          onChange={(e) => handleFieldChange(e)}
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm bg-blue-50"
        >
          <option value="">TUTTI</option>
          <option value="TGX">TGX</option>
          <option value="TGS">TGS</option>
          <option value="TGM">TGM</option>
          <option value="TGL">TGL</option>
          <option value="TGE">TGE</option>
          <option value="SEM">SEM</option>
        </select>
      </div>

      {filters.model !== "" && (
        <div
          className="text-xs text-blue-700 text-center cursor-pointer"
          onClick={toggleConfigFilters}
        >
          {showConfigFilters ? "CHIUDI FILTRI CONFIGURAZIONE" : "APRI FILTRI CONFIGURAZIONE"}
        </div>
      )}

      {showConfigFilters &&
        <>
          {filters.model === "TGX" && (
            <>
              <div>
                <label htmlFor="type" className="text-xs text-gray-500">
                  TIPO
                </label>
                <select
                  id="type"
                  name="type"
                  value={filters.type}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tgx.types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="cabine" className="text-xs text-gray-500">
                  CABINA
                </label>
                <select
                  id="cabine"
                  name="cabine"
                  value={filters.cabine}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tgx.cabineTypes.map((cabine) => (
                    <option key={cabine} value={cabine}>
                      {cabine}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="engine" className="text-xs text-gray-500">
                  MOTORE, hp
                </label>
                <select
                  id="engine"
                  name="engine"
                  value={filters.engine}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tgx.engines.map((engine) => (
                    <option key={engine} value={engine}>
                      {engine}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}

          {filters.model === "TGS" && (
            <>
              <div>
                <label htmlFor="type" className="text-xs text-gray-500">
                  TIPO
                </label>
                <select
                  id="type"
                  name="type"
                  value={filters.type}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tgs.types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="cabine" className="text-xs text-gray-500">
                  CABINA
                </label>
                <select
                  id="cabine"
                  name="cabine"
                  value={filters.cabine}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tgs.cabineTypes.map((cabine) => (
                    <option key={cabine} value={cabine}>
                      {cabine}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="engine" className="text-xs text-gray-500">
                  MOTORE, hp
                </label>
                <select
                  id="engine"
                  name="engine"
                  value={filters.engine}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tgs.engines.map((engine) => (
                    <option key={engine} value={engine}>
                      {engine}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}

          {(filters.model === "TGL" || filters.model === "TGM") && (
            <div>
              <label htmlFor="cabine" className="text-xs text-gray-500">
                CABINA
              </label>
              <select
                id="cabine"
                name="cabine"
                value={filters.cabine}
                onChange={(e) => handleFieldChange(e)}
                className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
              >
                <option value="">TUTTI</option>
                {manConfig.tgmTgl.cabineTypes.map((cabine) => (
                  <option key={cabine} value={cabine}>
                    {cabine}
                  </option>
                ))}
              </select>

              <div>
                <label htmlFor="engine" className="text-xs text-gray-500">
                  MOTORE, hp
                </label>
                <select
                  id="engine"
                  name="engine"
                  value={filters.engine}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {filters.model === "TGL"
                    ? manConfig.tgmTgl.engines.tgl.map((engine) => (
                      <option key={engine} value={engine}>
                        {engine}
                      </option>
                    ))
                    : manConfig.tgmTgl.engines.tgm.map((engine) => (
                      <option key={engine} value={engine}>
                        {engine}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
          )}

          {filters.model === "TGE" && (
            <>
              <div>
                <label htmlFor="type" className="text-xs text-gray-500">
                  TIPO
                </label>
                <select
                  id="type"
                  name="type"
                  value={filters.type}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tge.types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="wheelbase" className="text-xs text-gray-500">
                  PASSO
                </label>
                <select
                  id="wheelbase"
                  name="wheelbase"
                  value={filters.wheelbase}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tge.wheelbase.map((wheelbase) => (
                    <option key={wheelbase} value={wheelbase}>
                      {wheelbase}
                    </option>
                  ))}
                </select>
              </div>

              {filters.type === "FURGONE" && (
                <div>
                  <label htmlFor="top" className="text-xs text-gray-500">
                    TETTO
                  </label>
                  <select
                    id="top"
                    name="top"
                    value={filters.top}
                    onChange={(e) => handleFieldChange(e)}
                    className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                  >
                    <option value="">TUTTI</option>
                    {manConfig.tge.topTypes.map((top) => (
                      <option key={top} value={top}>
                        {top}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div>
                <label htmlFor="traction" className="text-xs text-gray-500">
                  TRAZIONE
                </label>
                <select
                  id="traction"
                  name="traction"
                  value={filters.traction}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tge.traction.map((traction) => (
                    <option key={traction} value={traction}>
                      {traction}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="trans" className="text-xs text-gray-500">
                  CAMBIO
                </label>
                <select
                  id="trans"
                  name="trans"
                  value={filters.trans}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.common.transmission.map((trans) => (
                    <option key={trans} value={trans}>
                      {trans}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="engine" className="text-xs text-gray-500">
                  MOTORE, hp
                </label>
                <select
                  id="engine"
                  name="engine"
                  value={filters.engine}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.tge.engines.map((engine) => (
                    <option key={engine} value={engine}>
                      {engine}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="color" className="text-xs text-gray-500">
                  COLORE
                </label>
                <select
                  id="color"
                  name="color"
                  value={filters.color}
                  onChange={(e) => handleFieldChange(e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
                >
                  <option value="">TUTTI</option>
                  {manConfig.common.colors.map((color) => (
                    <option key={color} value={color}>
                      {color}
                    </option>
                  ))}
                </select>
              </div>

            </>
          )}

          {filters.model && filters.model !== "TGE" && (
            <div>
              <label htmlFor="susp" className="text-xs text-gray-500">
                SOSPENSIONI
              </label>
              <select
                id="susp"
                name="susp"
                value={filters.susp}
                onChange={(e) => handleFieldChange(e)}
                className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
              >
                <option value="">TUTTI</option>
                {manConfig.common.suspension.map((susp) => (
                  <option key={susp} value={susp}>
                    {susp}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>}

      <div>
        <label htmlFor="created_year" className="text-xs text-gray-500">
          CREATO
        </label>
        <select
          id="created_year"
          name="created_year"
          onChange={(e) => handleFieldChange(e)}
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
        >
          <option value="">TUTTI</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </select>
      </div>

      {filters.com_status === "venduto" &&
        <>
          <div className="">
            <label htmlFor="sale_year" className="text-xs text-gray-500">
              ANNO VENDITA
            </label>
            <select
              id="sale_year"
              name="sale_year"
              onChange={(e) => handleFieldChange(e)}
              className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
            >
              <option value="">TUTTI</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
          </div>

          <div className="">
            <label htmlFor="invoice_year" className="text-xs text-gray-500">
              ANNO FATTURAZIONE
            </label>
            <select
              id="invoice_year"
              name="invoice_year"
              onChange={(e) => handleFieldChange(e)}
              className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
            >
              <option value="">TUTTI</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
          </div>

          <div>
            <label htmlFor="manager" className="text-xs text-gray-500">
              VENDITORE
            </label>
            <select
              id="manager"
              name="manager"
              value={filters.manager}
              onChange={(e) => handleFieldChange(e)}
              className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
            >
              <option value="">TUTTI</option>
              {sellers?.map((manager) => (
                <option key={manager.id} value={manager.user}>
                  {manager.name}
                </option>
              ))}
            </select>
          </div>

          <div className="">
            <label htmlFor="fin_status" className="text-xs text-gray-500">
              STATUS FATTURAZIONE
            </label>
            <select
              id="fin_status"
              name="fin_status"
              onChange={(e) => handleFieldChange(e)}
              className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
            >
              <option value="">TUTTI</option>
              <option value="fatturato">Fatturato</option>
              <option value="non fatturato">Non fatturato</option>
              <option value="fatturato incompleto">Fatturato incompleto</option>
            </select>
          </div>
        </>
      }

      <div className="mt-4">
        <label htmlFor="prod_status" className="text-xs text-gray-500">
          STATUS PRODUZIONE
        </label>
        <select
          id="prod_status"
          name="prod_status"
          onChange={(e) => handleFieldChange(e)}
          className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm bg-violet-50"
        >
          <option value="">TUTTI</option>
          <option value="fornito">Fornito</option>
          <option value="modificabile">Modificabile</option>
          <option value="in produzione">In Produzione</option>
          <option value="in consegna">In Consegna</option>
        </select>
      </div>



    </div>
  );
};
