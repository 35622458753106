import { useAppSelector } from "app/hooks";
import { CompanyFormWindow } from "entities/crm/companies/ui/CompanyFormWindow";
import { ContactFormWindow } from "entities/crm/contacts/ui/ContactFormWindow";
import { CreateLeadWindow } from "entities/crm/leads/ui/CreateLeadWindow";
import { LeadDetails } from "entities/crm/leads/ui/LeadDetails";
//import { LeadFilesWindow } from "entities/crm/leads/ui/LeadFilesWindow";
import { NewOrderWindow } from "entities/orders/ui/NewOrderWindow";
import { QuoteFormWindow } from "entities/quotes/ui/QuoteFormWindow";
import { ServiceFormWindow } from "entities/services/ui/ServiceFormWindow";
import { VehicleWindow } from "entities/vehicles/ui/VehicleWindow";

export const Windows = () => {
  const windows = useAppSelector((state) => state.windows);
  const orderWindows = useAppSelector((state) => state.orders.windows);
  const companyWindows = useAppSelector((state) => state.companies.windows);
  const contactWindows = useAppSelector((state) => state.contacts.windows);
  //const leadWindows = useAppSelector((state) => state.leads.windows);
  //const quoteWindows = useAppSelector((state) => state.quotes.windows);
  const vehicleWindows = useAppSelector((state) => state.vehicles.windows);
  const serviceWindows = useAppSelector((state) => state.services.windows);
  return (
    <>
      {orderWindows.newOrderWindow && <NewOrderWindow />}
      {companyWindows.companyFormWindow && <CompanyFormWindow />}
      {contactWindows.contactFormWindow && <ContactFormWindow />}

      {windows.createLeadWindow && <CreateLeadWindow />}
      {/* {leadWindows.leadFilesWindow && <LeadFilesWindow />} */}
      {windows.leadDetailsWindow && <LeadDetails />}

      {windows.quoteFormWindow && <QuoteFormWindow />}

      {vehicleWindows.vehicleWindow && <VehicleWindow />}
      {/* {serviceWindows.serviceCreateWindow && <ServiceCreateWindow />} */}
      {serviceWindows.serviceFormWindow && <ServiceFormWindow />}
    </>
  );
};
