export const TrailerSVG = ({ color }: { color?: string }) => {
  return (
    <svg
      width="40px"
      height="16px"
      viewBox="0 0 1104.8471 438.26994"
      version="1.1"
      id="svg1307"
    >
      <defs id="defs1304" />
      <g id="layer1" transform="translate(490.6222,89.988349)">
        <path
          d="M 614.22486,214.50826 H -490.6222 V -89.988349 H 614.22486 V 214.50826"
          fill={color}
          id="path224"
        />
        <path
          d="m -429.16408,214.50826 v 37.35563 h 13.96753 v -18.16452 h 155.04195 v 61.12227 h 33.52306 V 261.9992 H 97.419541 l 57.266419,-47.49094 h -583.85004"
          fill={color}
          id="path226"
        />
        <path
          d="m -69.783011,280.51298 c 0,36.45252 -29.548666,66.00472 -66.001189,66.00472 -36.45606,0 -66.00473,-29.5522 -66.00473,-66.00472 0,-36.45253 29.54867,-66.00472 66.00473,-66.00472 36.452523,0 66.001189,29.55219 66.001189,66.00472"
          fill={color}
          id="path238"
        />
        <path
          d="m -69.783011,280.51298 c 0,36.45252 -29.548666,66.00472 -66.001189,66.00472 -36.45606,0 -66.00473,-29.5522 -66.00473,-66.00472 0,-36.45253 29.54867,-66.00472 66.00473,-66.00472 36.452523,0 66.001189,29.55219 66.001189,66.00472 z"
          fill="none"
          id="path240"
        />
        <path
          d="m 80.729624,280.51298 c 0,36.45252 -29.552193,66.00472 -66.00472,66.00472 -36.452527,0 -66.004721,-29.5522 -66.004721,-66.00472 0,-36.45253 29.552194,-66.00472 66.004721,-66.00472 36.452527,0 66.00472,29.55219 66.00472,66.00472"
          fill={color}
          id="path242"
        />
        <path
          d="m 80.729624,280.51298 c 0,36.45252 -29.552193,66.00472 -66.00472,66.00472 -36.452527,0 -66.004721,-29.5522 -66.004721,-66.00472 0,-36.45253 29.552194,-66.00472 66.004721,-66.00472 36.452527,0 66.00472,29.55219 66.00472,66.00472 z"
          fill="none"
          id="path244"
        />
      </g>
    </svg>
  );
};
