import { useAppDispatch } from "app/hooks";
import { useSearchParams } from "react-router-dom";
import { openWindow } from "../model";

export const HistoryCard = ({ history }: { history: any }) => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
  
    const openOrderHandler = () => {
      searchParams.set("order", history.order_id.toString());
      setSearchParams(searchParams);
      dispatch(openWindow("newOrderWindow"));
    };
    return (<div className="text-xs cursor-pointer" key={history.id} onClick={openOrderHandler}>
        {`#${history.order_id}`} <span className="text-blue-800">{history.short_desc}</span>
    </div>);
};
