import { Stack } from "@mui/material";
import { Order } from "types/order";
import { OrderInlineEdit } from "../OrderInlineEdit";
import { useUpdateOrderMutation } from "entities/orders/model/rtk-api";
interface EditBageProps {
  order: Order;
}

export const EditBage = ({ order }: EditBageProps) => {
  const [updateOrder] = useUpdateOrderMutation();
  const handleStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {

    try {
      await updateOrder({
        id: order.id,
        status: e.target.value
      });
    } catch (error) {
      console.error('Errore durante l\'aggiornamento dello status:', error);
    }
  };
  return (
    <Stack>
      <div className="mb-4">
        Nome: <OrderInlineEdit order={order} field="name" className="name" />
      </div>
      {order.status !== "venduto" &&
        <div className="mb-4">
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={order.status}
            onChange={(e) => {
              handleStatusChange(e);
            }}
          >
            <option value="incompleto">Incompleto</option>
            <option value="in stock">In Stock</option>
            <option value="preventivo">Preventivo</option>
          </select>
        </div>
      }

      {order.type !== "MAN" && (
        <>
          <div>
            Model:{" "}
            <OrderInlineEdit
              order={order}
              field="model_desc"
              className="name"
            />
          </div>
          <div>
            Telaio:{" "}
            <OrderInlineEdit order={order} field="chassis" className="name" />
          </div>
          <div>
            Targa:{" "}
            <OrderInlineEdit order={order} field="plate" className="name" />
          </div>
        </>
      )}
    </Stack>
  );
};
