import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Config } from "types/order";
import { initConfig } from './constants';
import { manConfig } from 'entities/man/model/config';

type TgsType = "TRATTORE" | "CARRO" | "MEZZO D'OPERA";

interface ConfiguratorProps {
  model: string;
  filter?: boolean;
  config: Config;
  setConfig: (config: Config) => void;
}

interface SelectConfigProps {
  field: string;
  value: string;
  label: string;
  list: string[];
  filter: boolean;
}

export const Configurator = ({
  model,
  config,
  setConfig,
  filter = true,
}: ConfiguratorProps) => {
  const safeConfig = config || initConfig;

  const onChangeSelect = (event: SelectChangeEvent, field: string) => {
    setConfig({
      ...safeConfig,
      [field]: event.target.value
    });
  };

  const resetFilter = (field: string) => {
    setConfig({
      ...safeConfig,
      [field]: ""
    });
  };

  const SelectConfig = ({
    field,
    value,
    list,
    label,
    filter,
  }: SelectConfigProps) => {
    return (
      <FormControl
        size="small"
        fullWidth
      >
        <InputLabel id="label">{label}</InputLabel>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Select
            fullWidth
            labelId="label"
            value={value}
            label={label}
            onChange={(event: SelectChangeEvent) =>
              onChangeSelect(event, field)
            }
          >
            {filter && <MenuItem value="">-</MenuItem>}
            {list.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <ClearIcon
            sx={{ color: "grey", cursor: "pointer" }}
            onClick={() => resetFilter(field)}
          />
        </Stack>
      </FormControl>
    );
  };

  return (
    <Box sx={{ maxWidth: 300 }}>
      <Stack direction="column" spacing={3}>
        {model === "TGX" && (
          <>
            <SelectConfig
              field="type"
              value={safeConfig.type}
              label="Tipo"
              list={manConfig.tgx.types}
              filter={filter}
            />

            <SelectConfig
              field="cabine"
              value={safeConfig.cabine}
              label="Cabina"
              list={manConfig.tgx.cabineTypes}
              filter={filter}
            />

            <SelectConfig
              field="engine"
              value={safeConfig.engine}
              label="Motore, hp"
              list={manConfig.tgx.engines}
              filter={filter}
            />

            {safeConfig.type === "CARRO" && (
              <SelectConfig
                field="carload"
                value={safeConfig.carload}
                label="Portata"
                list={manConfig.tgx.carloads}
                filter={filter}
              />
            )}
          </>
        )}

        {model === "TGS" && safeConfig.type !== "" && (
          <>
            <SelectConfig
              field="type"
              value={safeConfig.type}
              label="Tipo"
              list={manConfig.tgs.types}
              filter={filter}
            />

            <SelectConfig
              field="cabine"
              value={safeConfig.cabine}
              label="Cabina"
              list={manConfig.tgs.cabineTypes}
              filter={filter}
            />

            <SelectConfig
              field="engine"
              value={safeConfig.engine}
              label="Motore, hp"
              list={manConfig.tgs.engines}
              filter={filter}
            />

            {(safeConfig.type as TgsType) === "TRATTORE" && (
              <SelectConfig
                field="carload"
                value={safeConfig.carload}
                label="Portata"
                list={manConfig.tgs.carloads.trattore}
                filter={filter}
              />
            )}

            {(safeConfig.type as TgsType) === "CARRO" && (
              <SelectConfig
                field="carload"
                value={safeConfig.carload}
                label="Portata"
                list={manConfig.tgs.carloads.carro}
                filter={filter}
              />
            )}

            {(safeConfig.type as TgsType) === "MEZZO D'OPERA" && (
              <SelectConfig
                field="carload"
                value={safeConfig.carload}
                label="Portata"
                list={manConfig.tgs.carloads.mezzo}
                filter={filter}
              />
            )}
          </>
        )}

        {(model === "TGL" || model === "TGM") && (
          <>
            <SelectConfig
              field="cabine"
              value={safeConfig.cabine}
              label="Cabina"
              list={manConfig.tgmTgl.cabineTypes}
              filter={filter}
            />

            <SelectConfig
              field="engine"
              value={safeConfig.engine}
              label="Motore, hp"
              list={model === "TGL" ? manConfig.tgmTgl.engines.tgl : manConfig.tgmTgl.engines.tgm}
              filter={filter}
            />
          </>
        )}

        {model && model !== "TGE" && (
          <SelectConfig
            field="susp"
            value={safeConfig.susp}
            label="Sospensioni"
            list={manConfig.common.suspension}
            filter={filter}
          />
        )}

        {model === "TGE" && (
          <>
            <SelectConfig
              field="type"
              value={safeConfig.type}
              label="Tipo"
              list={manConfig.tge.types}
              filter={filter}
            />

            {safeConfig.type === "FURGONE" && (
              <SelectConfig
                field="top"
                value={safeConfig.top}
                label="Tetto"
                list={manConfig.tge.topTypes}
                filter={filter}
              />
            )}

            <SelectConfig
              field="wheelbase"
              value={safeConfig.wheelbase}
              label="Passo"
              list={manConfig.tge.wheelbase}
              filter={filter}
            />

            <SelectConfig
              field="traction"
              value={safeConfig.traction}
              label="Trazione"
              list={manConfig.tge.traction}
              filter={filter}
            />

            <SelectConfig
              field="trans"
              value={safeConfig.trans}
              label="Cambio"
              list={manConfig.common.transmission}
              filter={filter}
            />

            <SelectConfig
              field="engine"
              value={safeConfig.engine}
              label="Motore, hp"
              list={manConfig.tge.engines}
              filter={filter}
            />

            <SelectConfig
              field="setup"
              value={safeConfig.setup}
              label="Allestimento"
              list={manConfig.tge.setups}
              filter={filter}
            />
          </>
        )}

        <SelectConfig
          field="color"
          value={safeConfig.color}
          label="Colore"
          list={manConfig.common.colors}
          filter={filter}
        />
      </Stack>
    </Box>
  );
};
