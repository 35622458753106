import { createSlice } from "@reduxjs/toolkit";

interface WindowsState {
  createLeadWindow: boolean;
  leadFilesWindow: boolean;
  leadDetailsWindow: boolean;
  quoteFormWindow: boolean;
  quoteDelWindow: boolean;
}

const initialState: WindowsState = {
  createLeadWindow: false,
  leadFilesWindow: false,
  leadDetailsWindow: false,
  quoteFormWindow: false,
  quoteDelWindow: false,
};

export const windowsSlice = createSlice({
  name: "windows",
  initialState,
  reducers: {
    openWindow: (state, { payload }) => {
      state[payload as keyof WindowsState] = true;
    },
    closeWindow: (state, { payload }) => {
      state[payload as keyof WindowsState] = false;
    },
  },
});

export const {
  openWindow,
  closeWindow,
} = windowsSlice.actions;

export default windowsSlice.reducer;
