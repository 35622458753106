import { Paper, Popper, Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { getCurrentOrder, ordersState } from "entities/orders/model";
import { useGetOrderHistoryQuery } from "entities/orders/model/rtk-api";
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import { History, ManFields, Order } from "types/order";

interface OrderHistoryProps {
  field: keyof History;
  desc: string;
  order: Order;
}

export const OrderHistory = ({ order, field, desc }: OrderHistoryProps) => {
  //const order = useAppSelector(getCurrentOrder);
  const {data: history, isLoading} = useGetOrderHistoryQuery(order.id);
  if (history && Object.keys(history).includes(field as string)) {
    const histField = [...history[field]].reverse();
    return (
      <PopupState variant="popper">
        {(popupState) => (
          <div>
            <div
              style={{
                color: "darkblue",
                fontSize: "13px",
                fontWeight: "lighter",
                cursor: "pointer",
              }}
              {...bindToggle(popupState)}
            >
              {histField[0].value || "---"}
            </div>
            <Popper disablePortal {...bindPopper(popupState)}>
              <Paper
                {...bindToggle(popupState)}
                sx={{ padding: "5px", zIndex: 10 }}
              >
                <Typography fontSize="11px">{desc}</Typography>
                {histField.map((item, index) => (
                  <div key={index}>
                    <Typography fontSize="11px" color="grey" key={index}>
                      {item.dt_changes} {">>"}{" "}
                      <span style={{ color: "black" }}>{item.value}</span>
                    </Typography>
                  </div>
                ))}
              </Paper>
            </Popper>
          </div>
        )}
      </PopupState>
    );
  }
  return (
    <Typography
      sx={{
        fontSize: "13px",
        fontWeight: "lighter",
      }}
    >
      {order[field as keyof ManFields] &&
        new Date(order[field as keyof ManFields]).toLocaleDateString("it-IT")}
    </Typography>
  );
};
